import React, { SetStateAction, useEffect, useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import Label from "../../../../../components/form/Label";
import { useCustomOverlays } from "../../../../sites/services";
import { Select } from "../../../../../components/form-v2";
function Sites() {
  const { data: overlays } = useCustomOverlays().overlaysQuery;
  const overlaysOptions = overlays.map((overlay) => ({
    value: overlay.id,
    label: overlay.name,
  }));
  const defaultOperationalMapFilters: {
    clientID: string;
    userTypes: string[];
    siteTypes: string[];
    trackingUserID: string | null;
    incidents: boolean;
    searchOverlayID: string | null;
  } = JSON.parse(localStorage.getItem("operational-map-filter-config") as string);
  const [isFacilities, setIsFacilities] = useState<boolean>(false);
  const [isGeofence, setisGeofence] = useState<boolean>(false);
  const [isSafetyZone, setIsSafetyZone] = useState<boolean>(false);
  const [isCustomOverlay, setIsOverlay] = useState<boolean>(false);

  const siteTypes = defaultOperationalMapFilters.siteTypes;

  useEffect(() => {
    setIsFacilities(siteTypes.includes("facilities"));
    setisGeofence(siteTypes.includes("geofence"));
    setIsSafetyZone(siteTypes.includes("safety-zone"));
    setIsOverlay(siteTypes.includes("custom-overlay"));
  }, []);

  return (
    <div>
      <Label text="Sites" />

      <div className={"my-2 flex flex-col gap-2"}>
        <div className={`${isFacilities ? null : "opacity-50"}`}>
          <Checkbox
            label="Facilities"
            name="sites_type"
            value="facilities"
            checked={isFacilities}
            onClick={() => setIsFacilities((prev) => !prev)}
          />
        </div>

        <div className={`${isGeofence ? null : "opacity-50"}`}>
          <Checkbox
            label="Geofence"
            name="sites_type"
            value="geofence"
            checked={isGeofence}
            onClick={() => setisGeofence((prev) => !prev)}
          />
        </div>
        <div className={`${isSafetyZone ? null : "opacity-50"}`}>
          <Checkbox
            label="Safety Zone"
            name="sites_type"
            value="safety-zone"
            checked={isSafetyZone}
            onClick={() => setIsSafetyZone((prev) => !prev)}
          />
        </div>
        <div className={`${isCustomOverlay ? null : "opacity-50"}`}>
          <Checkbox
            label="Custom Overlay"
            name="sites_type"
            value="custom-overlay"
            checked={isCustomOverlay}
            onClick={() => setIsOverlay((prev) => !prev)}
          />
        </div>
        {isCustomOverlay ? (
          <Select
            name="overlay"
            options={overlaysOptions}
            title="Search Overlays"
            defaultSelected={
              defaultOperationalMapFilters.searchOverlayID
                ? overlaysOptions.filter(
                    (overlay) =>
                      overlay.value ===
                      defaultOperationalMapFilters.searchOverlayID
                  )[0]
                : null
            }
          />
        ) : null}
      </div>
    </div>
  );
}

export default Sites;
