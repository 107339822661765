import React, { SetStateAction } from "react";
import { DropDownButton } from "../../../../components/ui/Buttons";
import Label from "../../../../components/form/Label";
import useConvertToTime from "../../../../hooks/useConvertToTime";
import { UserActivityDataTypes } from "../../../../types/user-activity-data-types";
import typeBasedTileColor from "../../constants/typeBasedTileColor";
import { userActivityDisplayLabel } from "../../../../types/user-activity";

function UserInformation({
  setDropDown,
  dropDown,
  userData,
}: {
  setDropDown: React.Dispatch<SetStateAction<boolean>>;
  dropDown: boolean;
  userData: UserActivityDataTypes;
}) {
  const tileColor = typeBasedTileColor(userData.type)!;
  const time = useConvertToTime(userData.timestamp);
  const type = userData.type.replace("_", " ");
  const { borderColor } = tileColor!;

  return (
    <div
      className={`relative text-sm w-full h-fit p-8 bg-white flex flex-col rounded-xl text-grey border-4 
      border-${borderColor} border-opacity-25`}
    >
      <span className="font-semibold">{userData.metadata.userName}</span>
      <span className="">{userData.metadata.clientName}</span>
      <span className="mr-5">
        {userData.metadata.departmentName ? 
          <span className="font-semibold">Dept: </span> 
          : null
        } 
          {userData.metadata.departmentName}</span>
      <div className=" mt-2 capitalize">
        <Label text="Time sent:" assignText={time} />
        <Label
          text="Type:"
          assignText={userActivityDisplayLabel(userData)}
          extraClass="capitalize"
        />
        <Label text="Source:" assignText={userData.source} />
      </div>
      <DropDownButton open={dropDown} onClick={() => setDropDown(!dropDown)} />
    </div>
  );
}

export default UserInformation;
