import React, { useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import Label from "../../../../../components/form/Label";

function StepTwo() {
  const [isAppPlus, setIsAppPlus] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isSMS, setIsSMS] = useState<boolean>(false);
  const [isChat, setIsChat] = useState<boolean>(false);
  return (
    <div>
      <div className="flex items-center justify-center">
        <Label text="Step 2:" assignText="Select communication method" />
      </div>
      <div className="opacity-60 text-sm inline-grid grid-cols-2 gap-[8px_32px] mt-4">
        <Checkbox
          label="App"
          name="communication-method"
          value="app-plus"
          checked={isAppPlus}
          onClick={() => setIsAppPlus((prev) => !prev)}
        />
        <Checkbox
          label="Email"
          name="communication-method"
          value="email"
          checked={isEmail}
          onClick={() => setIsEmail((prev) => !prev)}
        />
        <Checkbox
          label="SMS"
          name="communication-method"
          value="sms"
          checked={isSMS}
          onClick={() => setIsSMS((prev) => !prev)}
        />
        <Checkbox
          label="Chat"
          name="communication-method"
          value="chat"
          checked={isChat}
          onClick={() => setIsChat((prev) => !prev)}
        />
      </div>
    </div>
  );
}

export default StepTwo;
