import React, {useContext, useEffect, useRef, useState} from "react";
import BasicSelect from "../../components/form-v2/basic-select";
import {checkInTypes, travelSubtypesAccommodation, travelSubtypesTravel, travelTypes} from "./enum";
import {
  GeocodeLocations,
  ItineraryItem,
  ItineraryItemSubtype,
  ItineraryItemType,
  ItineraryLocation,
  ItineraryTime
} from "./types";
import {BlueButton, RedButton} from "../../components/ui/Buttons";
import BasicText, {BasicNumber} from "../../components/form-v2/basic-text";
import {v4 as uuid} from "uuid"
import {countries} from "../../constants/countries";
import {apiGET} from "../../apiv2/request";
import {getTimezonesForCountry} from "countries-and-timezones";
import {AccessTokenPayloadType, UserContext} from "../../context/user";
import UserSelect from "../../components/form-v2/searchable-select/UserSelect";
import {Client, Department} from "../../types/userManagement";
import DepartmentSelect from "../../components/form-v2/searchable-select/DepartmentSelect";
import ClientSelect from "../../components/form-v2/searchable-select/ClientSelect";
import {startCase} from "lodash";
import moment from "moment";
import {useTripFormContext} from "./context";
import {metadataFields, metadataTypes} from "./metadata";
import {CountrySelectSingle} from "../../components/form-v2/searchable-select/CountrySelectSingle";


const TripForm: React.FC = () => {


  const {role} = useContext(UserContext) as AccessTokenPayloadType

  const ctx = useTripFormContext()

  if (ctx.page == "trip") {
    return <>
      <ItineraryForm/>
      {(role.auroraAccessLevel != "user") && <UserForm/>}
    </>
  }

  if (ctx.page == "check_ins") {
    return <>
      <CheckInPreviewList/>
      <CheckInConfigForm/>
    </>
  }

  return <div></div>

}


type ItineraryFormProps = {}

const ItineraryForm: React.FC<ItineraryFormProps> = () => {


  const ctx = useTripFormContext()
  const [index, setIndex] = useState<number>(0)


  // const [itineraryItems, setItineraryItems] = useState<ItineraryItem[]>(items || [])


  const removeItineraryItem = (index: number) => {
    const newItems = [...ctx.itinerary];
    newItems.splice(index, 1);
    ctx.setItinerary(newItems)
    changeIndex(index - 1)
  }

  const newLocation = (): ItineraryLocation => {
    return {
      addressLines: [],
      latitude: 0,
      longitude: 0,
      region: "",
      countryISO: "",
      county: "",
      town: "",
      postalCode: "",
      timezone: "",
    }
  }

  const newTime = (): ItineraryTime => {
    return {
      date: "",
      time: "",
      timezone: "",
    }
  }


  const addItem = () => {
    const id = uuid()


    const newItem: ItineraryItem = {
      id,
      type: "travel",
      subtype: "flight",
      locations: [newLocation(), newLocation()],
      details: {},
      startTime: newTime(),
      endTime: newTime(),
    }

    const newItinerary = [...ctx.itinerary, newItem]
    ctx.setItinerary(newItinerary)

    setIndex(newItinerary.length - 1)

    window.scrollTo(0, 0);


  }

  useEffect(() => {
    if (ctx.itinerary.length == 0) {
      addItem()
    }
  }, []);

  const onItineraryItemChange = (item: ItineraryItem, index: number) => {
    const newItems = [...ctx.itinerary];
    newItems[index] = item
    ctx.setItinerary(newItems)
  }

  const changeIndex = (i: number) => {
    if (i < 0) {
      return
    }

    if (i > ctx.itinerary.length - 1) {
      return
    }
    setIndex(i)
  }


  return <div className="w-full shadow-md p-10 pt-4 bg-white">
    <Header text={ctx.mode == "create" ? "Create Trip" : "Edit Trip"}/>
    {ctx.itinerary[index] &&

        <ItineraryItemForm key={index}
                           item={ctx.itinerary[index]}
                           index={index}
                           onChange={onItineraryItemChange}
                           remove={removeItineraryItem}/>
    }
    {<BlueButton text={"Add Item"} onClick={addItem}/>}
    <hr className={"mt-4 mb-4 opacity-25"}/>

    <div className={"flex gap-8"}>
      {index > 0 ? <BlueButton text={"Prev"} onClick={() => changeIndex(index - 1)}/> :
          <div className={"w-full"}></div>}
      {index < ctx.itinerary.length - 1 ? <BlueButton text={"Next"} onClick={() => changeIndex(index + 1)}/> :
          <div className={"w-full"}></div>}

    </div>

    <hr className={"mt-4 mb-4 opacity-25"}/>
    {ctx.page == "trip" && <BlueButton text={"Next"} onClick={() => ctx.setPage("check_ins")}/>}
    {ctx.page == "check_ins" && <BlueButton text={"Next"} onClick={() => ctx.setPage("trip")}/>}
    {ctx.errors.map(e => <p key={e} className={"text-center text-red text-sm mt-2"}>{e}</p>)}
    {ctx.localErrors.map(e => <p key={e} className={"text-center text-red text-sm mt-2"}>{e}</p>)}

  </div>
}


const CheckInPreviewList: React.FC = () => {


  const ctx = useTripFormContext()


  const removeItineraryItem = (index: number) => {
    const newItems = [...ctx.itinerary];
    newItems.splice(index, 1);
    ctx.setItinerary(newItems)
  }

  const newLocation = (): ItineraryLocation => {
    return {
      addressLines: [],
      latitude: 0,
      longitude: 0,
      region: "",
      countryISO: "",
      county: "",
      town: "",
      postalCode: "",
      timezone: "",
    }
  }

  const newTime = (): ItineraryTime => {
    return {
      date: "",
      time: "",
      timezone: "",
    }
  }


  const addItem = () => {
    const id = uuid()


    const newItem: ItineraryItem = {
      id,
      type: "travel",
      subtype: "flight",
      locations: [newLocation(), newLocation()],
      details: {},
      startTime: newTime(),
      endTime: newTime(),
    }
    ctx.setItinerary([...ctx.itinerary, newItem])
  }

  useEffect(() => {
    if (ctx.itinerary.length == 0) {
      addItem()
    }
  }, []);


  return <div className="w-full shadow-md p-10 pt-4 bg-white">
    <Header text={"Preview Check Ins"}/>
    <CheckInPreview/>

    <hr className={"mt-4 mb-4 opacity-25"}/>
    {ctx.page == "trip" && <BlueButton text={"Next"} onClick={() => ctx.setPage("check_ins")}/>}
    {ctx.page == "check_ins" && <BlueButton text={"Back"} onClick={() => ctx.setPage("trip")}/>}


    <hr className={"mt-4 mb-4 opacity-25"}/>
    <BlueButton loading={ctx.saving} text={ctx.mode == "create" ? "Create Trip" : "Update Trip"}
                onClick={ctx.submitTrip}/>


    {ctx.errors.map(e => <p key={e} className={"text-center text-red text-sm mt-2"}>{e}</p>)}

  </div>
}


type ItineraryItemProps = {
  index: number
  item: ItineraryItem
  remove: (index: number) => void
  onChange: (item: ItineraryItem, index: number) => void
}

const getTimezoneOptions = (countryISO: string) => {
  return getTimezonesForCountry(countryISO)?.map((tz) => ({
    label: tz.name,
    value: tz.name
  })) || []
}

const ItineraryItemForm: React.FC<ItineraryItemProps> = (props) => {


  const [type, setType] = useState<ItineraryItemType>(props.item.type || "travel")
  const [subtype, setSubtype] = useState<ItineraryItemSubtype>(props.item.subtype || "flight")
  const [locations, setLocations] = useState<ItineraryLocation[]>([props.item.locations[0], props.item.locations[1] || {} as ItineraryLocation])

  const prevTypeRef = useRef<string>(props.item.type || "travel");
  const prevSubtypeRef = useRef<string>(props.item.type || "travel");


  const updateLocation = (location: ItineraryLocation, index: number) => {
    const newLocations = [...locations]
    newLocations[index] = location


    setLocations(newLocations)

  }


  const [startDate, setStartDate] = useState(props.item.startTime.date)
  const [startTime, setStartTime] = useState(props.item.startTime.time)
  const [startTimezone, setStartTimezone] = useState<string | undefined>(locations[0].timezone)

  const [endDate, setEndDate] = useState(props.item.endTime.date)
  const [endTime, setEndTime] = useState(props.item.endTime.time)

  const [endTimezone, setEndTimezone] = useState<string | undefined>(locations[locations.length - 1].timezone)

  const [metadata, setMetadata] = useState<Record<string, string>>(props.item.details)


  useEffect(() => {

    props.onChange({
      id: props.item.id,
      type: type,
      subtype: subtype,
      locations: type == "travel" ? [locations[0], locations[1]] : [locations[0]],
      startTime: {
        date: startDate,
        time: startTime,
        timezone: startTimezone as string,
      },
      endTime: {
        date: endDate,
        time: endTime,
        timezone: endTimezone as string,
      },
      details: metadata,
    }, props.index)

    if (type == "travel" && prevTypeRef.current != "travel") {
      setSubtype("flight")
    }

    if (type == "accommodation" && prevTypeRef.current != "accommodation") {
      setSubtype("hotel")
    }

    prevTypeRef.current = type
    prevSubtypeRef.current = subtype

  }, [type, subtype, locations, startDate, startTime, startTimezone, endDate, endTime, endTimezone, metadata]);


  const onMetadataChange = (key: string, value: string) => {


    const newMetadata = {
      ...metadata,
    }
    newMetadata[key] = value
    setMetadata(newMetadata)
  }


  return <div className={"mb-4"}>
    <div className={"grid grid-cols-2 gap-4 mb-4"}>
      <BasicSelect label={"Type"} options={travelTypes} value={props.item.type}
                   onChange={(value) => setType(value as ItineraryItemType)}/>
      <BasicSelect label={"Subtype"} options={type == "travel" ? travelSubtypesTravel : travelSubtypesAccommodation}
                   value={props.item.subtype} onChange={(value) => setSubtype(value as ItineraryItemSubtype)}/>

    </div>
    <MetadataForm type={type} subtype={subtype} metadata={metadata} onChange={onMetadataChange}/>

    <AddressForm title={type == "travel" ? "Departure Location" : "Accommodation Location"} index={0}
                 onChange={updateLocation} location={locations[0]}/>

    {locations[0].countryISO &&
        <div className={"grid grid-cols-3 gap-4 mb-4"}>
            <BasicText label={"Start Date"} type={"date"} value={startDate} onChange={setStartDate}/>
            <BasicText label={"Start Time"} type={"time"} value={startTime} onChange={setStartTime}/>
          {/* <BasicSelect label={"Timezone"} options={getTimezoneOptions(locations[0].countryISO)} value={startTimezone} onChange={setStartTimezone}/>*/}
        </div>
    }


    {type == "travel" ?
        <AddressForm title={"Arrival Location"} index={1} onChange={updateLocation} location={locations[1]}/> : null}

    {((type == "travel" && locations[1].countryISO) || locations[0].countryISO) &&
        <div className={"grid grid-cols-3 gap-4 mb-4"}>
            <BasicText label={"End Date"} type={"date"} value={endDate} onChange={setEndDate}/>
            <BasicText label={"End Time"} type={"time"} value={endTime} onChange={setEndTime}/>
          {/* <BasicSelect label={"Timezone"} options={getTimezoneOptions(locations[type == "travel" ? 1 : 0].countryISO)} value={endTimezone} onChange={setEndTimezone}/>*/}
        </div>
    }


    <RedButton text={"Remove"} onClick={() => props.remove(props.index)}/>
    <hr className={"mt-4 opacity-25"}/>
  </div>

}

type AddressFormProps = {
  title: string
  index: number
  onChange: (location: ItineraryLocation, index: number) => void
  location: ItineraryLocation
}

const AddressForm: React.FC<AddressFormProps> = ({index, title, onChange, location}) => {

  const [search, setSearch] = useState("");

  if (!location) {
    location = {
      addressLines: ["", "", ""],
      latitude: 0,
      longitude: 0,
      region: "",
      countryISO: "",
      county: "",
      town: "",
      postalCode: "",
      timezone: "",
    }
  }

  if (!location.addressLines) {
    location.addressLines = ["", "", ""];
  }

  const [addressLine1, setAddressLine1] = useState(location.addressLines[0]);
  const [addressLine2, setAddressLine2] = useState(location.addressLines[1]);
  const [addressLine3, setAddressLine3] = useState(location.addressLines[2]);
  const [town, setTown] = useState(location.town);
  const [county, setCounty] = useState(location.county);
  const [region, setRegion] = useState(location.region);
  const [postalCode, setPostalCode] = useState(location.postalCode);
  const [countryISO, setCountryISO] = useState<string | undefined>(location.countryISO);
  const [timezone, setTimezone] = useState<string | undefined>(location.timezone);
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>("");


  const [latitude, setLatitude] = useState(String(location.latitude || ""));
  const [longitude, setLongitude] = useState(String(location.longitude || ""));

  useEffect(() => {
    const location: ItineraryLocation = {
      addressLines: [addressLine1, addressLine2, addressLine3].filter((v) => v && v.trim()),
      town,
      county,
      region,
      postalCode,
      countryISO: countryISO || "",
      latitude: Number(latitude),
      longitude: Number(longitude),
      timezone: timezone || "",
    }

    console.log(index, location)

    onChange(location, index)


  }, [addressLine1, addressLine2, addressLine3, town, county, region, postalCode, countryISO, latitude, longitude, timezone]);

  const countryOptions = countries.map((c) => {
    return {value: c.code, label: c.name}
  })

  const [searchOptions, setSearchOptions] = useState<GeocodeLocations>([])

  const handleSearch = async () => {
    setSearchOptions([])

    const url = `${process.env.REACT_APP_API_V2_URL}/geo/geocode`
    const results = await apiGET<GeocodeLocations>(url, {query: search}, {})

    setSelectedLocation("")
    setSearchOptions(results)

  }

  const applyLocation = (indexStr: string | undefined) => {
    if (!indexStr) {
      return
    }
    const index = Number(indexStr)

    const selected = searchOptions[index]
    if (!selected) {
      return
    }


    setAddressLine1(selected.addressLines[0])
    setAddressLine2(selected.addressLines[1])
    setAddressLine3(selected.addressLines[2])
    setTown(selected.town)
    setCounty(selected.county)
    setRegion(selected.region)
    setCountryISO(selected.countryISO)
    setPostalCode(selected.postalCode)
    setLatitude(String(selected.latitude))
    setLongitude(String(selected.longitude))
    setTimezone(String(selected.timezone))
  }

  useEffect(() => {
    console.log(countryISO)
    if (!countryISO) {
      return
    }
    const timezones = getTimezoneOptions(countryISO)
    if (timezones.length == 0) {
      return
    }

    const current = timezone
    const found = timezones.some((tz) => tz.value === current);

    if (!found) {
      setTimezone(timezones[0].value)
    }
  }, [countryISO]);


  return <div className={"mb-8"}>
    <h2 className={"font-semibold mb-4"}>{title}</h2>
    <div className={"grid grid-cols-3 gap-4 align-bottom"}>
      <BasicText label={"Search"} value={search} onChange={setSearch}/>
      <div className={"self-end"}>
        <BlueButton text={"Search"} onClick={handleSearch}/>

      </div>
      <BasicSelect label={"Select Location"}
                   options={searchOptions.map((o, i) => ({value: String(i), label: o.formatted}))}
                   useEmptyOption={true}
                   value={selectedLocation}
                   onChange={applyLocation}
                   disabled={searchOptions.length == 0}

      />


      <BasicText label={"Address Line 1"} value={addressLine1} onChange={setAddressLine1}/>
      <BasicText label={"Address Line 2"} value={addressLine2} onChange={setAddressLine2}/>
      <BasicText label={"Address Line 3"} value={addressLine3} onChange={setAddressLine3}/>

      <BasicText label={"Town"} value={town} onChange={setTown}/>
      <BasicText label={"County"} value={county} onChange={setCounty}/>
      <BasicText label={"Region"} value={region} onChange={setRegion}/>

      {/* <BasicSelect label={"Country"} options={countryOptions} value={countryISO} onChange={setCountryISO}/>*/}
      <CountrySelectSingle value={countryISO} setValue={setCountryISO} label={"Country"}/>

      <BasicText label={"Postal Code"} value={postalCode} onChange={setPostalCode}/>
      {countryISO && <BasicSelect label={"Timezone"} options={getTimezoneOptions(countryISO)} value={timezone}
                                  onChange={setTimezone}/>}
      <BasicText label={"Latitude"} value={latitude} onChange={setLatitude}/>
      <BasicText label={"Longitude"} value={longitude} onChange={setLongitude}/>
      <div/>

    </div>


  </div>

}

// export type ItineraryLocation = {
//   countryISO: string
//   region: string
//   county: string
//   town: string
//   postalCode: string
//   addressLines: string[]
//   latitude: number
//   longitude: number
// }

const UserForm: React.FC = () => {


  const {role} = useContext(UserContext) as AccessTokenPayloadType;


  const [department, setDepartment] = useState<Department | undefined>()
  const [client, setClient] = useState<Client | undefined>()


  const ctx = useTripFormContext()


  return <div className="w-full max-w-md shadow-md p-10 pt-4 bg-white">
    {role.auroraAccessLevel != "user" &&
        <>
            <div className={"flex flex-col gap-4"}>
                <Header text={"Traveller"}/>
              {["all"].includes(role.auroraAccessLevel) &&
                  <ClientSelect clients={client ? [client] : []} onSelectionChange={(c) => setClient(c[0])}/>}
              {["all", "client"].includes(role.auroraAccessLevel) &&
                  <DepartmentSelect clientID={client?.id} departments={department ? [department] : []}
                                    onSelectionChange={(d) => setDepartment(d[0])}/>}
                <UserSelect clientID={client?.id} departmentID={department?.id}
                            users={ctx.user ? [ctx.user] : []}
                            onSelectionChange={(u) => ctx.setUser(u[0])}
                            initialUserIDs={ctx.user ? [ctx.user.id] : []}
                />
            </div>

        </>
    }
  </div>
}


const CheckInConfigForm: React.FC = () => {

  const ctx = useTripFormContext()

  const onTimeChange = (time: string, index: number) => {
    const newTimes = [...ctx.checkInTimes]
    newTimes[index] = time
    ctx.setCheckInTimes(newTimes)
  }

  const addTime = () => {
    ctx.setCheckInTimes([...ctx.checkInTimes, ""])
  }

  const removeTime = () => {
    const newTimes = [...ctx.checkInTimes]
    newTimes.pop()
    ctx.setCheckInTimes(newTimes)
  }

  if (!ctx.allowCheckInConfig) {
    return <div className="w-full max-w-md shadow-md p-10 pt-4 bg-white">
      <Header text={"Check-Ins"}/>
      <div>Check ins are using automatic configuration</div>
    </div>
  }

  return <div className="w-full max-w-md shadow-md p-10 pt-4 bg-white">
    <Header text={"Check-Ins"}/>
    <BasicSelect label={"Type"} options={checkInTypes.filter(t => t.value != "auto")} value={ctx.checkInType}
                 onChange={(value) => ctx.setCheckInType(value as string)}/>
    {!["none", "auto"].includes(ctx.checkInType as string) && <div className={"mt-4"}>
        <BasicNumber label={"Grace Period (Minutes)"} value={ctx.checkInGracePeriod} type={"number"} min={5}
                     max={ctx.checkInType == "set_times" ? 60 : 240}
                     onChange={(v) => ctx.setCheckInGracePeriod(Number(v))}/>
    </div>}
    {ctx.checkInType == "set_times" && <>
      {ctx.checkInTimes.map((time, i) => <div key={i} className={"mt-4"}>
        <BasicText label={"Time  + 1}"} value={time} type={"time"} onChange={(value) => onTimeChange(value, i)}/>
      </div>)}
        <div className={"grid grid-cols-2 gap-4 mt-4"}>
            <BlueButton text={"Add"} onClick={addTime}/>
          {ctx.checkInTimes.length > 1 && <RedButton text={"Remove"} onClick={removeTime}/>}
        </div>
    </>
    }

    <div className={"mt-4"}>
      <BlueButton text={"Update Preview"} onClick={ctx.getCheckInPreview}/>
    </div>

  </div>
}


type HeaderProps = {
  text: string
}
const Header: React.FC<HeaderProps> = ({text}) => {
  return <div className="flex items-center justify-between py-4 border-b text-md mb-4">
    <span className="font-semibold text-lg">{text}</span>
  </div>
}


type MetadataFormProps = {
  type?: string
  subtype?: string
  onChange: (key: string, value: string) => void
  metadata: Record<string, string>
}


const MetadataForm: React.FC<MetadataFormProps> = (props) => {
  if (!props.type || !props.subtype) {
    return null
  }

  const onChange = (field: string, value: string) => {
    const type = metadataTypes[field] || "text"

    if (type == "datetime-local") {
      const m = moment(value)
    }

    props.onChange(field, value)
  }


  return <>
    <h2 className={"font-semibold mb-4"}>Details</h2>
    <div className={"grid grid-cols-3 gap-4 align-bottom mb-4"}>
      {metadataFields[props.subtype]?.map((m) => <BasicText key={m} type={metadataTypes[m] || "text"}
                                                            label={startCase(m)}
                                                            value={props.metadata[m]}
                                                            onChange={(v) => onChange(m, v)}/>)}

    </div>
  </>

}


const CheckInPreview: React.FC = () => {


  const ctx = useTripFormContext()

  return <div>
    <CheckInHeader/>
    <div className={"grid grid-cols-6"}>
      {ctx.previewCheckIns && ctx.previewCheckIns.sort((a, b) => a.checkInAt.localeCompare(b.checkInAt)).map((c) => <>
        <div
            className={"text-center px-2 py-4"}>{moment.tz(c.checkInAt, c.location.timezone).format("DD/MM/YYYY")}</div>
        <div className={"text-center px-2 py-4"}>{moment.tz(c.checkInAt, c.location.timezone).format("HH:mm")}</div>
        <div className={"text-center px-2 py-4"}>
          <div>{c.location.countryName}</div>
          <div className={"text-sm"}>{c.location.timezone}</div>
        </div>
        <div className={"text-center px-2 py-4"}>{moment(c.checkInBy).diff(moment(c.checkInAt)) / (1000 * 60)}</div>
        <div className={"text-center px-2 py-4 col-span-2"}>{c.notes}</div>
      </>)}

    </div>
  </div>


}

const CheckInHeader: React.FC = () => {
  return (
      <div className={"rounded-t-lg bg-desaturated-grey py-3 grid grid-cols-6 w-full items-center h-full"}>
        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Date
        </div>
        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Time
        </div>

        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Location
        </div>
        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Grace Period
        </div>

        <div className="font-bold text-center px-6 h-full flex items-center justify-center col-span-2">
          Notes
        </div>

      </div>
  );
}


export default TripForm;