import React, { useContext, useEffect } from "react";
import ReactDatePicker from "react-datepicker";

import DropDown from "../../../../../components/form/DropDown";
import InputText from "../../../../../components/form/InputText";
import Checkbox from "../../../../../components/form/Checkbox";
import {
  BlueButton,
  ButtonBorderTransparent,
} from "../../../../../components/ui/Buttons";

import DateIcon from "../../../../../assets/icons/date.svg";
import Suitcase from "../../../../../assets/icons/suitcase-grey.svg";
import IconSearch from "../../../../../assets/icons/icon-search.svg";
import Download from "../../../../../assets/icons/download-blue.svg";
import { ReportsSummaryContext } from "../context/ReportsSummaryProvider";
import useGetAllClients from "../../../../../api/clients/useGetAllClients";
import {
  dateToStringWithDashes,
  downloadCSV,
} from "../../../../../util/helpers";
import api from "../../../../../api/axiosConfig";

export default function SummarySearchBox() {
  const {
    startDate,
    endDate,
    summaryData,
    setStartDate,
    setEndDate,
    selectedClients,
    setSelectedClients,
    getSummaryInfo,
    setCSVType,
    setCSVLoading,
  } = useContext(ReportsSummaryContext);

  const {
    all_clients_data: clients,
    all_clients_status: status,
    all_clients_error: error,
  } = useGetAllClients();

  const [clientsSearch, setClientsSearch] = React.useState<string>("");
  const [isDownloadEnabled, setIsDownloadEnabled] = React.useState(false);


  const handleClientSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientsSearch(e.target.value);
  };

  useEffect(() => {
    if (summaryData) {
      const isAnyFieldNonZero = summaryData["alerts-count"] > 0 ||
                                summaryData["all-count"] > 0 ||
                                summaryData["check-in-count"] > 0 ||
                                summaryData["tracking-count"] > 0;
  
      setIsDownloadEnabled(isAnyFieldNonZero);
    }
  }, [summaryData]);

  const getCSV = (type: "tracking" | "checkin" | "emergency" | "all") => {
    setCSVLoading(true);
    api
      .get("/reports/summary-csv", {
        params: {
          type,
          // reportID: summaryData.reportID,
          clientIDs: selectedClients.join(","),
          startTime: startDate,
          endTime: endDate,

        },
      })
      .then((res) => {
        setCSVLoading(false);
        downloadCSV(
          res.data,
          `${type}_${dateToStringWithDashes(
            startDate!,
            true
          )}_${dateToStringWithDashes(endDate!, true)}_summary_report.csv`
        );
      })
      .catch((err) => {
        setCSVLoading(false);
        console.error(err);
      });
  };

  const handleClientSelect = (target: HTMLInputElement) => {
    const client = target.value;
    if (selectedClients!.includes(client)) {
      setSelectedClients!(selectedClients!.filter((c) => c !== client));
    } else {
      setSelectedClients!([...selectedClients!, client]);
    }
  };

  const handleDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate!(start);
    setEndDate!(end);
  };

  const getUsersName = (id: string) => {
    const client = clients!.find((client) => client.id === id);
    return client!.name;
  };

  return (
    <div className="w-full max-w-[500px]">
      <div className="mb-3 px-3 font-bold text-sm">Search</div>
      <div className="bg-desaturated-grey py-10 px-14 flex flex-col w-full items-center">
        <DropDown
          dropDownIcon={DateIcon}
          title="Dates"
          transparentBox={true}
          selectedValue={
            startDate && endDate
              ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
              : ""
          }
          selected={startDate && endDate ? true : false}
        >
          <ReactDatePicker
            inline
            isClearable
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          />
        </DropDown>
        <div className="mt-4 w-full">
          <DropDown
            title="Clients"
            dropDownIcon={Suitcase}
            selected={selectedClients.length > 0}
            selectedValue={
              selectedClients!.length === 0
                ? ""
                : selectedClients!.length === 1
                ? getUsersName(selectedClients![0])
                : `Selected ${selectedClients.length} users`
            }
          >
            <div>
              <div className="mb-2">
                <InputText
                  placeholder="Search"
                  name="client"
                  value={clientsSearch}
                  onChange={handleClientSearchChange}
                />
                <button className="absolute top-0 right-3 translate-y-1/2">
                  <img src={IconSearch} alt="" />
                </button>
              </div>
              <div className="overflow-hidden h-[185px]">
                <div className="h-full overflow-y-scroll scrollbar-hide px-2">
                  {status === "success" &&
                    clients &&
                    clients.map((client) => {
                      if (
                        client.name
                          .toLowerCase()
                          .includes(clientsSearch.toLowerCase())
                      ) {
                        return (
                          <div className="mt-2">
                            <Checkbox
                              key={client.id + client.name}
                              name="client"
                              checked={selectedClients.includes(client.id)}
                              value={client.id}
                              label={client.name}
                              onClick={(target) => {
                                handleClientSelect(target);
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </DropDown>
        </div>
        <div className="mt-4 w-[200px]">
          <BlueButton text="Search" onClick={getSummaryInfo} />
        </div>
        <div className="mt-4 w-[200px]">
          <ButtonBorderTransparent
            text="Download Summary"
            onClick={() => getCSV("all")}
            icon={Download}
            disabled={!isDownloadEnabled}          
            />
        </div>
      </div>
    </div>
  );
}
