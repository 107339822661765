import {
  ButtonBorderTransparent,
  ButtonDownload,
} from "../../../../../components/ui/Buttons";
import React, { useContext } from "react";
import { ReportsSummaryContext } from "../context/ReportsSummaryProvider";
import {
  dateToStringWithDashes,
  downloadCSV,
} from "../../../../../util/helpers";
import api from "../../../../../api/axiosConfig";

type DataType = {
  title: string;
  value: string;
};

export default function SummaryReport({
  title,
  data,
}: {
  title: string;
  data: DataType[];
}) {
  const { summaryData, summaryStatus, startDate, setCSVLoading, endDate, selectedClients } =
    useContext(ReportsSummaryContext);

  const getCSV = (type: "tracking" | "checkin" | "emergency" | "all") => {
    setCSVLoading(true);
    api
      .get("/reports/summary-csv", {
        params: {
          type,
          clientIDs: selectedClients.join(","),
          startTime: startDate,
          endTime: endDate,

        },
      })
      .then((res) => {
        setCSVLoading(false);
        downloadCSV(
          res.data,
          `${type}_${dateToStringWithDashes(
            startDate!,
            true
          )}_${dateToStringWithDashes(endDate!, true)}_summary_report.csv`
        );
      })
      .catch((err) => {
        setCSVLoading(false);
        console.error(err);
      });
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-[500px]">
        <div className="mb-3 px-3 font-bold text-sm">{title}</div>
        <div className="flex w-full">
          <div className="bg-desaturated-grey w-full py-3 px-6 max-w-[500px]">
            <div className="pt-2 border-b border-light-grey w-full grid grid-cols-4 pb-2 justify-items-center">
              <div>Users</div>
              <div>Checkins</div>
              <div>Tracking</div>
              <div>Emergency</div>
            </div>
            <div className="grid grid-cols-4 w-full mt-4 justify-items-center">
              {summaryStatus === "success" && (
                <>
                  <div>{summaryData["all-count"]}</div>
                  <div>{summaryData["check-in-count"]}</div>
                  <div>{summaryData["tracking-count"]}</div>
                  <div>{summaryData["alerts-count"]}</div>
                </>
              )}
            </div>
            <div className="grid grid-cols-4 w-full mt-4 justify-items-center">
              <ButtonDownload onClick={() => getCSV("all")} />
              <ButtonDownload onClick={() => getCSV("checkin")} />
              <ButtonDownload onClick={() => getCSV("tracking")} />
              <ButtonDownload onClick={() => getCSV("emergency")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
