import { useContext, useEffect, useRef } from "react";
import useLocationHistory, {
  UserLocationHistory,
} from "../services/useLocationHistory";
import GreenPin from "../../../../../assets/icons/pin-green.png";
import { FilterMapContext } from "../../../context/filter-map";
import GreyCircle from "../../../../../assets/icons/grey-circle.png";
import moment from "moment";
import api from "../../../../../api/axiosConfig";
async function renderBreadcrumbInfo(userLocation: UserLocationHistory) {
  const getUserTimezone = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
  };
  const fetchTime = async (lat: string, lng: string) => {
    return await api
      .get(`util/coords-to-timezone?lat=${lat}&lng=${lng}`)
      .then((res) => {
        return moment(userLocation.endTime).tz(res.data.timeZoneId).format("YYYY-MM-DD HH:mm:ss Z");
      })
      .catch((err) => console.error(err));
  };
  const travellerTimezone = await fetchTime(
    userLocation.coordinates[1].toString(),
    userLocation.coordinates[0].toString()
  );

  const operatorTimezone = moment()
    .tz(getUserTimezone())
    .format("YYYY-MM-DD HH:mm:ss Z");
  const contentString = `
                <div class="map-incident-dialog">
                  <p class="font-semibold">${
                    userLocation.name
                  } - Active Tracking</p>
                  <p>
                    <span>User:</span>
                     ${userLocation.name}
                  </p>
                  <p><span>Client:</span>${userLocation.clientName}</p>
                  <p><span>Times Updated:</span>${userLocation.pointCount}</p>
                  <p><span>Timestamp (Traveller Timezone):</span> ${travellerTimezone}</p>
                  <p><span>Timestamp (Operator Timezone):</span> ${moment(userLocation.endTime).format("YYYY-MM-DD HH:mm:ss Z")}</p>
                  <p><span>Battery Level:</span>${
                    !userLocation.battery
                      ? "N/A"
                      : userLocation.pointCount === 1
                      ? userLocation.battery
                      : userLocation.battery[0] -
                        userLocation.battery[userLocation.battery?.length - 1]
                  }</p>
                  <p><span>Speed:</span>${
                    userLocation.pointCount > 1 ? "N/A" : userLocation.speed
                  }</p>
                  <p><span>Bearing:</span>${userLocation.bearing}</p>
                </div>`;

  return contentString;
}

export default function useTrackingHistory(map: google.maps.Map | undefined) {
  const { filters, setFilters } = useContext(FilterMapContext);
  const { trackingUserID: userID } = filters;
  const { data: userLocations } = useLocationHistory();

  const polylinePathInner = useRef<google.maps.Polyline>();
  const polylinePathOuter = useRef<google.maps.Polyline>();
  const startMarker = useRef<google.maps.Marker>();
  const endMarker = useRef<google.maps.Marker>();
  const breadcrumbMarkers = useRef<google.maps.Marker[]>();

  const reset = () => {
    polylinePathInner.current?.setMap(null);
    polylinePathOuter.current?.setMap(null);
    startMarker.current?.setMap(null);
    endMarker.current?.setMap(null);
    breadcrumbMarkers.current?.forEach((crumb) => crumb.setMap(null));
    polylinePathInner.current = undefined;
    polylinePathOuter.current = undefined;
    startMarker.current = undefined;
    endMarker.current = undefined;
    breadcrumbMarkers.current = undefined;
    setFilters((prev) => ({
      ...prev,
    }));
  };

  useEffect(() => {
    if (!map) return;
    reset();
    if (!userID || !userLocations || userLocations.length < 1) return;
    setFilters((prev) => ({ ...prev}));
    const firstCoords: [number, number] = userLocations[0].coordinates;
    const lastCoords = userLocations[userLocations.length - 1].coordinates;

    startMarker.current = new google.maps.Marker({
      map,
      icon: {
        url: GreenPin,
        scaledSize: new google.maps.Size(40, 40),
      },
      position: { lng: firstCoords[0], lat: firstCoords[1] },
      zIndex: 9999,
    });
    const infowindow = new google.maps.InfoWindow();
    infowindow.setZIndex(9998);
    startMarker.current.addListener("click", async () => {
      infowindow.setContent(await renderBreadcrumbInfo(userLocations[0]));
      infowindow.setPosition({ lng: firstCoords[0], lat: firstCoords[1] });
      infowindow.open(map);
    });

    endMarker.current = new google.maps.Marker({
      map,
      position: { lng: lastCoords[0], lat: lastCoords[1] },
      label: {
        text: "\ue56a",
        fontFamily: "Material Icons",
        color: "#ffffff",
        fontSize: "18px",
      },
      zIndex: 9999,
    });
    endMarker.current.addListener("click", async () => {
      infowindow.setContent(
        await renderBreadcrumbInfo(userLocations[userLocations.length - 1])
      );
      infowindow.setPosition({ lng: lastCoords[0], lat: lastCoords[1] });
      infowindow.open(map);
    });

    map.setZoom(12);
    map.setCenter({
      lng: lastCoords[0],
      lat: lastCoords[1],
    });
    const infoMarkers = userLocations.slice(1, -1).map((location) => {
      const position = {
        lng: location.coordinates[0],
        lat: location.coordinates[1],
      };
      const infoMarker = new google.maps.Marker({
        position,
        clickable: true,
        map,
        icon: {
          url: GreyCircle,
          scaledSize: new google.maps.Size(10, 10),
        },
        zIndex: 9999,
      });
      infoMarker.addListener("click", async () => {
        infowindow.setContent(await renderBreadcrumbInfo(location));
        infowindow.setPosition(position);
        infowindow.open(map);
      });
      return infoMarker;
    });
    breadcrumbMarkers.current = infoMarkers;

    const path = userLocations.map((location) => ({
      lng: location.coordinates[0],
      lat: location.coordinates[1],
    }));

    const polyPathInner = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#d2c7fc",
      strokeOpacity: 0.5,
      strokeWeight: 1,
      zIndex: 9999,
    });
    polylinePathInner.current = polyPathInner;
    polylinePathInner.current.setMap(map);

    const polyPathOuter = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#8b71e8",
      strokeOpacity: 1.0,
      strokeWeight: 3,
      icons: [
        {
          icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
          offset: "100%",
          repeat: "200px",
        },
      ],
      zIndex: 9999,
    });
    polylinePathOuter.current = polyPathOuter;
    polylinePathOuter.current.setMap(map);
  }, [userID, userLocations, map]);

  useEffect(() => {
    if (!map) return;
    if (userID) {
      breadcrumbMarkers.current?.forEach((marker) => {
        marker.setMap(map);
      });
    } else {
      breadcrumbMarkers.current?.forEach((marker) => {
        marker.setMap(null);
      });
      breadcrumbMarkers.current = undefined;
    }
  }, [map, breadcrumbMarkers.current, userID]);

  useEffect(() => {
    if (!map) return;
    if (userID) {
      startMarker.current?.setMap(map);
      endMarker.current?.setMap(map);
    } else {
      startMarker.current?.setMap(null);
      endMarker.current?.setMap(null);
    }
  }, [userID, map]);

  return userLocations;
}
