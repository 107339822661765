import React from "react";
import OperationalMap from "../../components/Map";
import IncidentDetailsModalProvider from "../../../../context/incident-details-modal";
function Main() {
  return (
    <div className="flex-1 relative h-full">
      <IncidentDetailsModalProvider>
        <OperationalMap />
      </IncidentDetailsModalProvider>
    </div>
  );
}

export default Main;
