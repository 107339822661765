import React, { useContext, useRef } from "react";
import CrossIcon from "../../assets/icons/icon-cross.svg";
import Overview from "./Overview";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { IncidentDataTypes } from "../../types/incident-data-types";
import moment from "moment";
import useConvertToTime from "../../hooks/useConvertToTime";
import { IncidentDetailsModalContext } from "../../context/incident-details-modal";

function IncidentDetails({ incident }: { incident: IncidentDataTypes }) {
  console.log(incident);
  const incidentModalsContext = useContext(IncidentDetailsModalContext)!;

  const incidentPopUpRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(incidentPopUpRef, () =>
    incidentModalsContext.setIncidentsModal(false)
  );

  const startedAt = moment(incident.startedAt).format("D/MM/Y");
  const startedAtTime = useConvertToTime(incident.startedAt);
  const CloseButton = () => (
    <button
      className="h-6 w-6 flex items-center justify-center rounded-full bg-[#707070] bg-opacity-50 absolute right-4 top-4"
      onClick={() => incidentModalsContext.setIncidentsModal(false)}
    >
      <img src={CrossIcon} alt="" className="pointer-events-none" />
    </button>
  );
  if (!incidentModalsContext.incidentDetails) return null;

  return (
    <div
      className="p-8 flex flex-col gap-2 w-full h-fit"
      ref={incidentPopUpRef}
    >
      <CloseButton />
      <h4 className="text-center font-semibold">Incident Details</h4>
      <hr className="my-4" />
      <div className="flex flex-col gap-2  ">
        {incident.summary && (
          <div className="">
            <p className="font-semibold">Summary:</p>
            <p>{incident.summary}</p>
          </div>
        )}
        {incident.description && (
          <div className="">
            <p className="font-semibold">Description:</p>
            <p>{incident.description}</p>
          </div>
        )}
      </div>

      <div className="w-full flex flex-col gap-4 capitalize h-fit">
        <Overview
          shortDescription={incident.severity}
          category_name={incident.typeName}
          startedAtDate={startedAt}
          startedAtTime={startedAtTime}
          link={incident.reference && incident.reference.length > 0 ? incident.reference[0].link : null}
        />
        {/* <hr className="my-4 w-full" />
        <PeopleAffected />
        <hr className="my-4 w-full" />
        <ClientsAffected /> */}
      </div>
    </div>
  );
}

export default IncidentDetails;
