import React from "react";
import AddIcon from "../../../../../assets/icons/add-icon.svg";
import Label from "../../../../../components/form/Label";
import Search from "../../../../../components/form/Search";
import { GreyButton } from "../../../../../components/ui/Buttons";
function StepThree() {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-center">
        <Label text="Step 3:" assignText="Select message content" />
      </div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <Search placeholder="App Templates" />
        <Search placeholder="SMS Templates" />
        <Search placeholder="Email Templates" />
        <Search placeholder="Audio Templates" />
      </div>
      <div className="text-sm mt-6">
        <GreyButton
          text="Add email attachmnet"
          icon={AddIcon}
          customClass={"w-fit"}
        />
      </div>
    </div>
  );
}

export default StepThree;
