import React, { ChangeEvent, useState } from "react";

function TextArea({ maxWordCount }: { maxWordCount?: number }) {
  const [wordCount, setWordCount] = useState<number>(0);

  const checkWordCount = (e: ChangeEvent<HTMLTextAreaElement>) => {};

  return (
    <div className="">
      <textarea
        name=""
        id=""
        cols={30}
        rows={10}
        className="border outline-none py-2 px-4 bg-desaturated-grey border-none rounded-lg w-full overflow-hidden resize-none"
        maxLength={maxWordCount}
        onChange={checkWordCount}
        placeholder={"Enter custom message"}
      />
      <span className="flex justify-end text-xs font-semibold">
        {wordCount} / {maxWordCount}
      </span>
    </div>
  );
}

export default TextArea;
