import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "../../../../../../assets/icons/icon-settings.svg";
import BinIcon from "../../../../../../assets/icons/icon-bin.svg";
import { ClientDataTypes } from "../../../../../../types/client-data-types";
import Modal from "../../../../../../components/portal/Modal";
import useGetAllClientDepartments from "../../../../../../api/client-departments/useGetAllClientDepartments";
import DeleteClient from "../../../../modals/delete-client";
import api from "../../../../../../api/axiosConfig";
import { useMutation, useQueryClient } from "react-query";
function Row({ client }: { client: ClientDataTypes }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => {
      return api.delete(`/clients/${client.id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("clients");
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const department = useGetAllClientDepartments(client.id)!;

  if (department.data) {
  }

  const handleDelete = () => {
    mutation.mutate();
  };

  return (
    <div className="border-b-1 border-light-grey py-4 px-5 flex">
      <div className="grid grid-cols-4 justify-items-center w-full">
        <Link
          to={`/user-management/client-users/${client.id}`}
          className="text-left w-full"
        >
          {client.name}
        </Link>
        <div>{client.userCount}</div>
        <div>{client.departmentCount}</div>
        <div className="flex items-center">
          <Link
            className="mr-5"
            to={`/user-management/client/${client.id}/edit-client`}
          >
            <img className="w-6 h-6" src={SettingsIcon} alt="settings icon" />
          </Link>
          <button className="max-w-xs" onClick={() => setDeleteModal(true)}>
            <img className="w-6 h-6" src={BinIcon} alt="bin icon" />
          </button>
        </div>
      </div>

      {deleteModal && (
        <Modal open={deleteModal} setOpen={setDeleteModal}>
          <DeleteClient
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDelete={handleDelete}
            client={client}
          />
        </Modal>
      )}
    </div>
  );
}

export default Row;
