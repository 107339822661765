import React, { SetStateAction } from "react";
import Label from "../../../../components/form/Label";
import TextArea from "../../../../components/form/TextArea";
import { BlueButton, GreyButton } from "../../../../components/ui/Buttons";
import StepOne from "./components/StepOne";
import StepThree from "./components/StepThree";
import StepTwo from "./components/StepTwo";
import SendIcon from "../../../../assets/icons/send-icon.svg";
import AddIcon from "../../../../assets/icons/add-icon.svg";
function FormStepOne({
  setFormStep,
}: {
  setFormStep: React.Dispatch<SetStateAction<1 | 2>>;
}) {
  return (
    <>
      <header className="flex items-center justify-center">
        <Label text="1. Send Notification" />
      </header>

      <hr className="my-4 opacity-50" />

      <form action="" className="text-center">
        <StepOne />
        <hr className="my-4 opacity-50" />
        <StepTwo />
        <hr className="my-4 opacity-50" />
        <StepThree />
        <div className="relative">
          <hr className="my-4 opacity-50" />
          <span className="absolute top-2/4 -translate-y-2/4 left-2/4 -translate-x-2/4 bg-white px-8 font-light">
            or
          </span>
        </div>

        <TextArea maxWordCount={160} />

        <div className="grid grid-cols-[50%_50%] items-center justify-center pt-4">
          <div className="w-full mx-auto px-4">
            <BlueButton text="Send" icon={SendIcon} />
          </div>
          <div className="w-full mx-auto px-4">
            <GreyButton
              text={"Add follow up alerts"}
              icon={AddIcon}
              customClass={"bg-opacity-50"}
              onClick={() => setFormStep(2)}
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default FormStepOne;
