import React, {useContext, useEffect, useState} from "react";
import LocationIcon from "../../../../assets/icons/location-target.svg";
import PersonIcon from "../../../../assets/icons/icon-person.svg";
import FileIcon from "../../../../assets/icons/icon-file.svg";
import MessageIcon from "../../../../assets/icons/icon-message.svg";
import {WhiteButton} from "../../../../components/ui/Buttons";
import TimeStamps from "../TimeStamps";
import Label from "../../../../components/form/Label";
import Modal from "../../../../components/portal/Modal";
import {UserActivityDataTypes} from "../../../../types/user-activity-data-types";
import {apiV2} from "../../../../api/axiosConfig";
import moment from "moment";
import typeBasedTileColor from "../../constants/typeBasedTileColor";
import {useNavigate} from "react-router-dom";
import Message from "../../components/Message";
import {useQueryClient} from "react-query";
import ResolveConfirmation from "../resolve-confirmation";
import {FilterMapContext} from "../../context/filter-map";
import {UserContext} from "../../../../context/user";

function UserStatusCardDropDown({
                                  dropDown,
                                  userData,
                                  sidebarType,
                                  setVisible,
                                }: {
  dropDown: boolean;
  userData: UserActivityDataTypes;
  sidebarType: "non-emergency" | "emergency";
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {filters, setFilters} = useContext(FilterMapContext);
  const [resolveModal, setResolveModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const tileColor = typeBasedTileColor(userData.type);
  const [sendNotificationPopup, setSendNotificationPopup] =
      useState<boolean>(false);
  const [isTracking, setIsTracking] = useState<boolean>(false);
  const [isSOPError, setIsSOPError] = useState<boolean>(false);

  const handleResolveActivity = () => {
    apiV2
        .patch(`ops/user-activity/${userData.id}/resolve`, {})
        .then(() => {
          queryClient.invalidateQueries("user-activity");
          setResolveModal(false);
          setVisible(false);
        })
        .catch((err) => console.error(err));
  };

  const downloadedSOPFile = async () => {
    fetch(
        `${process.env.REACT_APP_API_URL}files/sop?clientID=${userData.clientID}`,
        {
          headers: {"Content-Type": "application/pdf"},
        }
    )
        .then((response) => {
          if (response.status == 404) {
            throw new Error("file not found");
          }
          return response;
        })
        .then((response) => response.blob())
        .then((blob) => {
          if (blob) {
            const downloadLink = document.createElement("a");
            const objectURL = URL.createObjectURL(blob);
            downloadLink.target = "_blank";
            downloadLink.href = objectURL;
            downloadLink.download = "sop.pdf";
            document.body.appendChild(downloadLink);

            downloadLink.click();

            document.body.removeChild(downloadLink);

            URL.revokeObjectURL(objectURL);
          }
        })
        .catch((error) => {
          setIsSOPError(true);
          console.error("Failed to download file:", error);
        });
  };

  const SOPError = () => {
    return (
        <p className="text-red text-sm">
          You do not have an SOP uploaded. Please contact
          t2ops@northcottglobalsolutions.com to enable this feature.
        </p>
    );
  };

  useEffect(() => {
    setTimeout(() => setIsSOPError(false), 10000);
  }, [isSOPError]);

  const handleUserLiveLocation = () =>
      setFilters((prev) => ({
        ...prev,
        trackingUserID: userData.userID,
      }));

  const handleStopUserLiveTracking = () =>
      setFilters((prev) => ({...prev, trackingUserID: null}));

  useEffect(() => {
    handleStopUserLiveTracking();
    if (isTracking) {
      handleUserLiveLocation();
    } else {
      handleStopUserLiveTracking();
    }
  }, [isTracking]);

  const userContext = useContext(UserContext);

  const receivedAtDate = moment(userData.timestamp).format("DD/MM/YYYY");
  const receivedAtTime = moment(userData.timestamp).format("LTS");

  return (
      <div
          className={`bg-white w-full text-grey rounded-xl h-fit pt-8 px-4 border-4 border-${
              tileColor!.borderColor
          } border-opacity-20 overflow-hidden mt-4 ${dropDown ? "" : "hidden"}`}
      >
        <div className="flex flex-col gap-2">
          {sidebarType === "emergency" && (
              <button
                  onClick={() => setResolveModal(true)}
                  className={`shadow-lg px-5 w-full h-10 rounded-lg flex items-center justify-center gap-2 text-sm bg-${
                      tileColor!.color
                  } transition-colors text-white`}
              >
                <div className="flex-1">
                  {userData.type === "check_in_missed" ? "Check In" : "Resolve"}
                </div>
              </button>
          )}

          {userData.type === "check_in_missed" ? null : (
              <WhiteButton
                  text={
                    filters.trackingUserID === userData.userID
                        ? "Stop Tracking"
                        : "Live Location"
                  }
                  onClick={
                    filters.trackingUserID === userData.userID
                        ? handleStopUserLiveTracking
                        : handleUserLiveLocation
                  }
                  icon={LocationIcon}
              />
          )}

          <WhiteButton
              text={"View Profile"}
              onClick={() => navigate(`/user-profile/${userData.userID}`)}
              icon={PersonIcon}
          />
          <WhiteButton
              text={"View SOPs"}
              onClick={() => downloadedSOPFile()}
              icon={FileIcon}
          />
          {isSOPError && <SOPError/>}

          <WhiteButton
              text={"Message"}
              onClick={() => setSendNotificationPopup(true)}
              icon={MessageIcon}

          />
        </div>
        <TimeStamps
            text="Received at:"
            whoseTimeZone="Operator Timezone"
            date={receivedAtDate}
            time={receivedAtTime}
        />

        {userData.location?.properties?.battery && (
            <div className={"py-4 border-b"}>
              <Label text="Battery Level"/>
              <span className="text-sm">
            {userData.location?.properties?.battery}%
          </span>
            </div>
        )}

        {userData.location?.properties?.speed && (
            <div className="py-4">
              <Label text="Speed"/>
              <span className="text-sm">
            {userData.location?.properties?.speed} m/s
          </span>
            </div>
        )}

        {userData.metadata.message && (
            <div className="py-4">
              <Label text="Message"/>
              <p>{userData.metadata.message}</p>
            </div>
        )}

        {userData.metadata.incident && (
            <>
              <div className="py-4">
                <Label text="Incident Summary"/>
                <p>{userData.metadata.incident.summary}</p>
              </div>

              <div className="py-4">
                <Label text="Incident Type"/>
                <p>{userData.metadata.incident.category} &ndash; {userData.metadata.incident.type}</p>
              </div>
              <div className="py-4">
                <Label text="Incident Severity"/>
                <p className={"capitalize"}>{userData.metadata.incident.severity}</p>
              </div>

            </>

        )}


        <Modal open={sendNotificationPopup} setOpen={setSendNotificationPopup}>
          <Message
              userData={userData}
              setSendNotificationPopup={setSendNotificationPopup}
          />
        </Modal>
        <Modal open={resolveModal} setOpen={setResolveModal}>
          <ResolveConfirmation
              deleteModal={resolveModal}
              setDeleteModal={setResolveModal}
              handleDelete={handleResolveActivity}
              userActivity={userData}
          />
        </Modal>
      </div>
  );
}

export default UserStatusCardDropDown;
