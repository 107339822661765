import { useQuery } from "react-query";
import api from "../axiosConfig";
import { FacilityDataTypes } from "../../types/facilitiy-data-types";
export default function useGetAllClientFacilities(id: string) {
  const fetchFacilities = async (page: number) => {
    return await api
      .get(`/facilities?clientID=${id}&page=${page}`, { params: { rpp: 200 } })
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const facilitiesQuery = useQuery(["facilities", id], () =>
    fetchFacilities(1)
  );

  const facilitiesQueryData = facilitiesQuery.data?.data.items;
  const fetchAllFacilities = async () => {
    const totalFacilities = facilitiesQuery.data?.data.total;
    const facilitiesPerPage = facilitiesQuery.data?.data.items.length;
    const totalPagesCalc = Math.ceil(totalFacilities / facilitiesPerPage);
    const pageFetches = Array.from({ length: totalPagesCalc }, (v, i) =>
      fetchFacilities(i)
    );

    return await Promise.all(pageFetches);
  };

  const { data, status, error } = useQuery({
    queryKey: ["client-facilities", id],
    queryFn: () => fetchAllFacilities(),
    enabled: !!facilitiesQueryData,
    staleTime: 0,
  });

  const extractFacilitiesFromEachPage = () => {
    if (status !== "success") return [];

    const arr: FacilityDataTypes[] = [];
    data.map((page) => {
      const items = page!.data.items;
      items?.forEach((item: FacilityDataTypes) => {
        arr.push(item);
      });
    });

    return arr;
  };
  const extractedData = extractFacilitiesFromEachPage();

  return {
    data: extractedData,
    status,
    error,
  };
}
