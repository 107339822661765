import React, { useEffect } from "react";
import { Sidebar, Main } from "../../features/operational-map";
import FilterMapContextProvider from "../../features/operational-map/context/filter-map";
function OperationalMap() {
  const userLoggedIn = JSON.parse(localStorage.getItem("user") as string);
  const handleResetFilters = () => {
    const defaultFilters = {
      clientID: userLoggedIn.metadata.clientID,
      userTypes: ["app", "trip", "facility", "satellite"],
      siteTypes: [],
      trackingUserID: null,
      incidents: true,
    };
    localStorage.setItem(
      "operational-map-filter-config",
      JSON.stringify(defaultFilters)
    );
  };

  useEffect(() => {
    const isOperationalMapFiltersSet = JSON.parse(
      localStorage.getItem("operational-map-filter-config") as string
    );
    if (isOperationalMapFiltersSet) return;
    handleResetFilters();
  }, []);
  return (
    <FilterMapContextProvider>
      <div className="h-screen pt-28 flex">
        <Sidebar sidebarType="non-emergency" key={"non-emergency"} />
        <Main />
        <Sidebar sidebarType="emergency" key={"emergency"} />
      </div>
    </FilterMapContextProvider>
  );
}

export default OperationalMap;
