import React, { useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import Label from "../../../../../components/form/Label";

function StepTwo() {
  return (
    <div>
      <div className="flex items-center justify-center">
        <Label
          text="Step 2:"
          assignText="Select when you want people to receive the follow-up"
        />
      </div>
      <div className="w-full flex items-center justify-center gap-6">
        <input type="text" />
        <select name="time-frame" id="time-frame">
          <option value="minutes" selected>
            Minutes
          </option>
          <option value="hours">Hours</option>
        </select>
      </div>
    </div>
  );
}

export default StepTwo;
