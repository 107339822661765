import WhiteLogo from "../../components/ui/WhiteLogo";
import React, { useContext } from "react";
import { routesPath } from "./routesPath";
import { Link } from "react-router-dom";
import SettingIcon from "../../assets/icons/icon-settings.svg";
import PublicIcon from "../../assets/icons/icon-public.svg";
import { useOidc } from "@axa-fr/react-oidc";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../../context/user";
import {
  useRestrictedSettings,
  useRestrictedUserManagement,
} from "../../restrictions";
function Header() {
  const userContext = useContext(UserContext)!;
  const auroraModules = userContext!.role.auroraModules;
  const { logout, isAuthenticated } = useOidc();
  const accessUserManagement = useRestrictedUserManagement();
  const firstSettingsLink = useRestrictedSettings()[0];
  const handleLogout = async () => {
    isAuthenticated && (await logout());
  };
  const userFirstNameInitial = userContext.profile.firstName.split("")[0];
  const userLastNameInitial = userContext.profile.lastName.split("")[0];
  return (
    <header className="fixed z-[100] bg-white bg-gradient-to-r from-[#2D166F_50%] to-[#007584] h-28 w-screen px-6 flex">
      <div className="h-full">
        <WhiteLogo />
      </div>
      {/**
       * PAGE LINKS
       */}
      <nav className="flex items-end w-full ml-16">
        <ul className="flex flex-row text-white justify-start w-full">
          {routesPath.map((route, i) => {
            if (
              auroraModules?.includes("all") ||
              auroraModules?.includes(route.slug)
            ) {
              if (route.slug === "e_learning") {
                return (
                  <li key={route.slug}>
                    <a
                      href={route.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-4"
                    >
                      {route.title}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li className="" key={route.slug} id={`route-${route.slug}`}>
                    <Link className={"px-4 border-r"} to={route.path}>
                      <span className="inline-block h-12 hover:border-b-4">
                        {route.title}
                      </span>
                    </Link>
                  </li>
                );
              }
            }
          })}
        </ul>
      </nav>

      {/**
       * USER MENU
       */}

      <div className="flex text-white gap-4 justify-center items-center">
        {!firstSettingsLink ? null : (
          <Link
            className="w-10 h-10 rounded-md flex items-center justify-center bg-white"
            to={firstSettingsLink.path}
          >
            <img src={SettingIcon} alt="" />
          </Link>
        )}

        {accessUserManagement && (
          <Link
            to={
              userContext.role.name === "NGS Super Admin"
                ? "/user-management"
                : `/user-management/client-users/${userContext.metadata.clientID}`
            }
            className="w-10 h-10 rounded-md flex items-center justify-center bg-white"
          >
            <img src={PublicIcon} alt="" />
          </Link>
        )}
        <Link
          className="w-10 h-10 rounded-md flex items-center justify-center bg-blue font-semibold text-md"
          to="profile"
        >
          {`${userFirstNameInitial}${userLastNameInitial}`}
        </Link>

        <button
          className="w-10 h-10 rounded-md flex items-center justify-center bg-white font-semibold text-xl"
          onClick={handleLogout}
        >
          <LogoutIcon style={{ color: "grey" }} />
        </button>
      </div>
    </header>
  );
}

export default Header;
