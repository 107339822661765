import React, {useContext, useState} from "react";
import {Link, useParams} from "react-router-dom";

import useGetTripsCheckIns from "../../../../api/trips-check-ins/useGetTripsCheckIns";

import AddIcon from "../../../../assets/icons/add-icon.svg";
import {BlueButton} from "../../../../components/ui/Buttons";
import CheckInEntry from "./components/CheckInEntry";
import DeletePopUp from "./components/DeletePopUp";
import NewCheckInPopUp from "./components/NewCheckInPopUp";
import IndividualTripHeader from "./components/IndividualTripHeader";
import {CheckInType, EditCheckIn} from "../../types/check-ins-data-types";
import EditCheckInPopUp from "./components/EditCheckInPopUp";
import {NewCheckIn, TripsContext} from "../../context/trips";
import {useQueryClient} from "react-query";
import moment from "moment";
import {apiDELETE, apiPOST, apiPUT} from "../../../../apiv2/request";

require("moment-timezone");

export default function IndividualTrip() {
  const {id: tripId} = useParams();
  const queryClient = useQueryClient();
  const {
    newCheckIn,
    selectedCheckIn,
    setSelectedCheckIn,
    fetchFilteredTrips,
  } = useContext(TripsContext);
  const {data: checkIns, status} = useGetTripsCheckIns(tripId!);

  const [showNewCheckInPopUp, setShowNewCheckInPopUp] =
      useState<boolean>(false);
  const [showEditCheckInPopUp, setShowEditCheckInPopUp] =
      useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [checkInIdToDelete, setCheckInIdToDelete] = useState<string>("");
  const [tripIdToDelete, setTripIdToDelete] = useState<string>("");
  const [tripDestinationName, setTripDestinationName] = useState<string>("");
  const [openCheckInId, setOpenCheckInId] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("")

  function calculateGracePeriod(checkInAt: Date, checkInBy: Date): number {
    // Parse the date strings into Date objects
    const checkInAtDate = new Date(checkInAt);
    const checkInByDate = new Date(checkInBy);

    // Calculate the difference in milliseconds
    const diffMs = checkInByDate.getTime() - checkInAtDate.getTime();

    // Convert milliseconds to minutes
    // 1 second = 1000 ms, 1 minute = 60 seconds
    const diffMinutes = Math.round(diffMs / (1000 * 60));

    return Number(diffMinutes);
  }

  const closeEditCheckInPopUp = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowEditCheckInPopUp(false);
    }
  };

  const closeNewCheckInPopUp = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowNewCheckInPopUp(false);
    }
  };

  const openEditCheckInPopUp = (tripID: string, checkInID: string) => {
    setErrorMessage("")
    if (checkInID) {
      const checkIn = checkIns.find(
          (checkIn: CheckInType) => checkIn.id === checkInID
      );

      setSelectedCheckIn!({
        tripID: tripID,
        checkInAt: checkIn?.checkInAt ? new Date(checkIn.checkInAt) : null,
        gracePeriod: calculateGracePeriod(
            checkIn?.checkInAt ? new Date(checkIn.checkInAt) : new Date(),
            checkIn?.checkInBy ? new Date(checkIn.checkInBy) : new Date()
        ),
        location: {
          countryISO: checkIn?.location.countryISO
              ? checkIn.location.countryISO
              : "",
          timezone: checkIn?.location.timezone ? checkIn.location.timezone : "",
          // city: checkIn?.location.city ? checkIn.location.city : "",
        },
        notes: checkIn?.notes || "",
      });
      setOpenCheckInId(checkInID);
      setShowEditCheckInPopUp(true);
    }
  };

  const openDeleteModal = (tripID: string, checkInID: string) => {
    if (tripID && checkInID) {
      setTripIdToDelete(tripID);
      setCheckInIdToDelete(checkInID);
      setShowDeleteModal(true);
    }
  };

  const deleteCheckIn = () => {

    apiDELETE(`${process.env.REACT_APP_API_V2_URL}/trips/${tripIdToDelete}/scheduled-check-ins/${checkInIdToDelete}`).then((res) => {
      console.log("deleted the check in from the database");
      queryClient.invalidateQueries("trips-check-ins");
      queryClient.invalidateQueries("trips");
      setShowDeleteModal(false);
    })
        .catch((err) => {
          console.log(err);
        });


  };

  const closeDeleteModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowDeleteModal(false);
    }
  };

  const saveEditCheckInAndClose = (data: EditCheckIn) => {
    data.gracePeriod = Number(data.gracePeriod);

    const o: any = {...data};
    o.checkInAt = moment(data.checkInAt)
        .tz(data.location.timezone, true)
        .format("YYYY-MM-DDTHH:mm:ssZ");

    apiPUT(`${process.env.REACT_APP_API_V2_URL}/trips/${tripId}/scheduled-check-ins/${openCheckInId}`, {
      time: {
        time: moment(data.checkInAt).format("HH:mm"),
        date: moment(data.checkInAt).format("YYYY-MM-DD"),
      },
      countryISO: data.location.countryISO,
      gracePeriod: Number(data.gracePeriod)
    }).then((res) => {
      console.log("changed the existing check in");
      queryClient.invalidateQueries("trips-check-ins");
      queryClient.invalidateQueries("trips");
      setShowEditCheckInPopUp(false);
    }).catch((err) => {
      const e = err as { error: string }
      console.log(e)
      setErrorMessage(e?.error || "An error occurred")
    });
  }

  const saveNewCheckInAndClose = (data: NewCheckIn) => {
    if (!data) {
      return;
    }

    const time = {
      date: moment(data.checkInAt).format("YYYY-MM-DD"),
      time: moment(data.checkInAt).format("HH:mm"),
      timezone: data.location.timezone
    }

    const req = {
      time,
      countryISO: data.location.countryISO,
      notes: data.notes,
      gracePeriod: Number(data.gracePeriod),
    }

    apiPOST(`${process.env.REACT_APP_API_V2_URL}/trips/${tripId}/scheduled-check-ins`, req).then(() => {
      setShowNewCheckInPopUp(false)
      queryClient.invalidateQueries("trips-check-ins");
      queryClient.invalidateQueries("trips");
    }).catch((err) => {
      const e = err as { error: string }
      console.log(e)
      setErrorMessage(e?.error || "An error occurred")
    })
  };

  return (
      <div className="h-screen pt-36 pb-16">
        <div className="flex w-full justify-between items-center px-16 pb-5">
          <div className="flex">
            <Link to="/trips">Trips Table</Link>
            <span className="mx-5">{">"}</span>
            <span>{tripDestinationName} Trip</span>
          </div>
          <div className="w-[200px]">
            <BlueButton
                text="Add New Check In"
                onClick={() => setShowNewCheckInPopUp(true)}
                icon={AddIcon}
            />
          </div>
        </div>
        <div className="w-full bg-white h-full pb-20 px-20 pt-8">
          {checkIns && checkIns.length !== 0 ? (
              <>
                <IndividualTripHeader/>
                <div className="overflow-hidden h-[calc(100vh_-_400px)] pt-3">
                  <div className="overflow-y-scroll h-full">
                    {status === "error" && <div>Error, try again</div>}
                    {status === "loading" && <div>Loading...</div>}
                    {status === "success" &&
                        checkIns.map((checkIn: CheckInType) => {
                          return (
                              <CheckInEntry
                                  key={checkIn.id}
                                  data={checkIn}
                                  openPopUp={(tripID, checkInID) => openEditCheckInPopUp(tripID, checkInID)}
                                  onDelete={(tripID, checkInID) => openDeleteModal(tripID, checkInID)}
                              />
                          );
                        })}
                  </div>
                </div>
              </>
          ) : (
              <div>No Check Ins</div>
          )}
        </div>
        {showNewCheckInPopUp && (
            <NewCheckInPopUp
                closeCheckInPopUp={closeNewCheckInPopUp}
                saveAndClose={saveNewCheckInAndClose}
                errorMessage={errorMessage}
            />
        )}
        {showEditCheckInPopUp && (
            <EditCheckInPopUp
                errorMessage={errorMessage}
                closeCheckInPopUp={closeEditCheckInPopUp}
                checkIn={selectedCheckIn!}
                saveAndClose={(data) => saveEditCheckInAndClose(data)}
            />
        )}
        {showDeleteModal && (
            <DeletePopUp
                popUpText="You are about to delete a Check In."
                closeDeleteModal={closeDeleteModal}
                deleteEntry={deleteCheckIn}
            />
        )}
      </div>
  );
}
