import React from "react";
import Label from "../../../../components/form/Label";
import { BlueButton, GreyButton } from "../../../../components/ui/Buttons";
import StepOne from "./components/StepOne";
import StepThree from "./components/StepThree";
import StepTwo from "./components/StepTwo";
import SendIcon from "../../../../assets/icons/send-icon.svg";
import AddIcon from "../../../../assets/icons/add-icon.svg";

function SendFollowUp() {
  return (
    <>
      <header className="flex items-center justify-center w-full">
        <Label text="2. Send Follow-up" />
      </header>

      <hr className="my-4 opacity-50" />

      <form action="" className="text-center w-full">
        <StepOne />
        <hr className="my-4 opacity-50" />
        <StepTwo />
        <hr className="my-4 opacity-50" />
        <StepThree />

        <div className="w-full mx-auto px-4 mt-8">
          <BlueButton text="Send" icon={SendIcon} />
        </div>
      </form>
    </>
  );
}

export default SendFollowUp;
