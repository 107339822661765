import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./components/ui/Footer";
import { useOidc } from "@axa-fr/react-oidc";
import UserContextProvider from "./context/user";
import Header from "./layout/header";
function App() {
  const [isProduction] = useState(process.env.REACT_APP_PRODUCT_ENV);
  const { isAuthenticated } = useOidc();
  return (
    <div className="App min-h-screen h-fit text-grey bg-desaturated-grey flex flex-col">
      <UserContextProvider>
        <div
          className={`fixed top-4 right-2/4 rounded-full translate-x-2/4 px-20 py-4 z-[110] bg-red text-white ${
            isProduction === "false" ? "flex" : "hidden"
          }`}
        >
          Dev Mode Testing
        </div>
        {isAuthenticated ? <Header /> : null}
        <div className="flex-1">
          <Outlet />
        </div>
        {isAuthenticated ? <Footer /> : null}
      </UserContextProvider>
    </div>
  );
}

export default App;
