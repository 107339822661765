import React, { RefObject, useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import FormSection from "../form-section";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { ClientDataTypes } from "../../../../../../types/client-data-types";
import useGetAllClientDepartments from "../../../../../../api/client-departments/useGetAllClientDepartments";
import useGetAllClientFacilities from "../../../../../../api/client-facilities/useGetAllClientFacilities";
import { DepartmentDataTypes } from "../../../../../../types/department-data-types";
import { UserProfileContext } from "../../context/user-profile";
import EmployeeID from "./EmployeeID";
import JobTitle from "./JobTitle";
import ClientName from "./ClientName";
import Department from "./Department";
import StationedFacility from "./StationedFacility";
import HomeFacility from "./HomeFacility";
function EmploymentDetails() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const clientDepartments = useGetAllClientDepartments(userData.clientID);
  const departments: DepartmentDataTypes[] = clientDepartments.data;
  const clientFacilities = useGetAllClientFacilities(userData.clientID);
  const facilities = clientFacilities?.data;
  return (
    <FormSection title={"Employment Details"}>
      <div className="grid grid-cols-4 gap-8">
        <EmployeeID />
        <JobTitle />
        <ClientName />

        {clientDepartments.status !== "success" ? null : (
          <Department
            departments={departments}
            defaultDepartmentID={userData.departmentID}
          />
        )}

        {clientFacilities.status !== "success" ? null : (
          <StationedFacility facilities={facilities} />
        )}

        {clientFacilities.status !== "success" ? null : (
          <HomeFacility facilities={facilities} />
        )}
      </div>
    </FormSection>
  );
}

export default EmploymentDetails;
