import { IncidentDataTypes } from "../../../types/incident-data-types";
import { useContext, useEffect, useRef } from "react";
import { IncidentDetailsModalContext } from "../../../context/incident-details-modal";
import { generateCircleRadius } from "../../../util/map";
import { FilterMapContext } from "../../operational-map/context/filter-map";

const useIncidentsMarker = (
  map: google.maps.Map | undefined,
  incidentsData: IncidentDataTypes[]
) => {
  const { filters } = useContext(FilterMapContext);
  const { incidents: isIncidents } = filters;
  const incidentDetailsModalContext = useContext(IncidentDetailsModalContext)!;
  const { setIncidentDetails, setIncidentsModal } = incidentDetailsModalContext;
  const circlesRef = useRef<google.maps.Circle[]>([]);

  useEffect(() => {
    if (!map) return;
    const circles: google.maps.Circle[] = incidentsData.map(
      (incident: IncidentDataTypes) => {
        const lng = incident.position.coordinates[0];
        const lat = incident.position.coordinates[1];
        const incidentRadius = generateCircleRadius(
          map,
          incident.severity,
          lat,
          lng
        );
        const circleLngLat = {
          lng: incidentRadius.getCenter()?.lng(),
          lat: incidentRadius.getCenter()?.lat(),
        };

        const handleMarkerClick = (lng: number, lat: number) => {
          const incident = incidentsData.find((incident: IncidentDataTypes) => {
            const incidentPos = incident.position.coordinates;
            if (incidentPos[0] === lng && incidentPos[1] === lat) {
              return incident;
            }
            return null;
          });
          if (!incident) {
            return console.error("Failed to get this incident.");
          } else {
            setIncidentsModal(true);
            setIncidentDetails(incident);
          }
        };

        incidentRadius.addListener("click", () => {
          handleMarkerClick(circleLngLat.lng!, circleLngLat.lat!);
        });
        return incidentRadius;
      }
    );

    circlesRef.current = circles;
  }, [incidentsData, map]);

  useEffect(() => {
    if (!map) return;
    if (isIncidents) {
      circlesRef.current.forEach((circle) => circle.setMap(map));
    } else {
      circlesRef.current.forEach((circle) => circle.setMap(null));
    }
  }, [map, isIncidents]);
};

export default useIncidentsMarker;
