import api from "../../../../../api/axiosConfig";
import moment from "moment";
import convertDateToUTC from "../../../../../util/convertDateToUTC";
import { useQuery } from "react-query";
import generateRandomToken from "../../../../../util/generateToken";
import { useContext, useState } from "react";
import { FilterMapContext } from "../../../context/filter-map";

export type UserLocationHistory = {
  coordinates: [number, number];
  type: string;
  startTime: string;
  endTime: string;
  bearing: string;
  pointCount: number;
  speed: number | null;
  battery: number[] | null;
  name: string;
  departmentName: string;
  clientName: string;
};

export default function useLocationHistory() {
  const { filters } = useContext(FilterMapContext);
  const { trackingUserID: userID } = filters;
  const [endTime, setEndTime] = useState<string>(
    convertDateToUTC(moment().format())
  );
  const fetchUserLocationHistory = async () => {
    const token = generateRandomToken(12);
    if (!userID) return;
    const startTime = convertDateToUTC(moment().subtract(1, "days").format());
    const params = {
      startTime,
      endTime,
    };
    return await api
      .get(`ops/user-location-feed/${userID}?token=${token}`, { params })
      .then((res) => {
        const data: UserLocationHistory[] = res.data;
        return data;
      })
      .catch((err) => console.error(err));
  };

  return useQuery(["user-location-history", userID], fetchUserLocationHistory, {
    refetchInterval: 5 * 60 * 1000,
    onSuccess: (data) => {
      const newEndtime = convertDateToUTC(moment().format());
      setEndTime(newEndtime);
    },
  });
}
