import { useEffect, useState } from "react";
import api from "../api/axiosConfig";
import { useInfiniteQuery } from "react-query";
import { UserDataTypes } from "../types/user-data-types";

export default function useUsers(params?: {
  clientID?: string | undefined | null;
  departmentID?: string | undefined | null;
}) {
  const [items, setItems] = useState<UserDataTypes[]>([]);

  const fetchData = async (page: number) => {
    if (!params?.clientID) return;
    return await api
      .get(`users?page=${page}`, { params: { ...params, rpp: 200 } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => console.error(err));
  };

  const {
    data,
    isLoading,
    isError,
    error,
    status,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    params?.clientID ? ["users", params.clientID] : "users",
    ({ pageParam = 1 }) => fetchData(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.hasMore) {
          return allPages.length + 1;
        }
        return undefined;
      },
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, hasNextPage, fetchNextPage]);
  useEffect(() => {
    if (hasNextPage) return;
    const flatItems = data ? data.pages.flatMap((page) => page?.items) : [];
    setItems(flatItems as []);
  }, [data, hasNextPage]);

  const usersQuery = {
    data: items,
    isLoading,
    isError,
    error,
    status,
    hasNextPage,
  };

  return { usersQuery };
}
