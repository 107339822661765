type KeysType = {
  slug: string;
  name: string;
  color: string;
  level: number;
}[];
export const keys = [
  {
    slug: "high-negliable",
    name: "Negliable",
    color: "#0CA817",
    level: 1,
  },
  {
    slug: "med-negliable",
    name: "Negliable",
    color: "#73B146",
    level: 2,
  },
  {
    slug: "high-low",
    name: "Low",
    color: "#B4BC00",
    level: 3,
  },
  {
    slug: "med-low",
    name: "Low",
    color: "#D8B700",
    level: 4,
  },
  {
    slug: "high-medium",
    name: "Medium",
    color: "#E6920A",
    level: 5,
  },
  {
    slug: "med-medium",
    name: "Medium",
    color: "#E5680C",
    level: 6,
  },
  {
    slug: "high-high",
    name: "High",
    color: "#E23603",
    level: 7,
  },
  {
    slug: "med-high",
    name: "High",
    color: "#BA1F1F",
    level: 8,
  },
  {
    slug: "high-extreme",
    name: "Extreme",
    color: "#A15FB2",
    level: 9,
  },
];
