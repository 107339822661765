import React from "react";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import DownloadIcon from "../../../../../assets/icons/download-grey.svg";
import { useLocation } from "react-router-dom";
import { AdviceGuidesDataTypes } from "../../../../../types/advice-guides-type";
import { downloadFile } from "../../../../../util/downloadFile";
import api from "../../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
import useGetSavedAdviceGuides from "../../../../../api/advice-guides/useGetSavedAdviceGuides";

function GuideListItem({ guide }: { guide: AdviceGuidesDataTypes }) {
  const savedGuides = useGetSavedAdviceGuides();
  const queryClient = useQueryClient();
  const guide_name = useLocation().pathname.split("/");
  const borderColor = guide_name.includes("natural-hazards")
    ? "border-[#E6920A]"
    : guide_name.includes("medical")
    ? "border-[#C64805]"
    : guide_name.includes("emergency")
    ? "border-[#A15FB2]"
    : "border-[#68AF6D]";

  const handleDownloadGuide = () =>
    downloadFile(
      `${process.env.REACT_APP_API_URL}files/advice-guide/${guide.id}`,
      guide.name
    );

  const addToSavedGuides = () => {
    api.post(`saved/advice-guides?id=${guide.id}`).then((res) => {
      queryClient.invalidateQueries("saved-advice-guides");
    });
  };
  return (
    <li
      className={`flex items-center justify-between px-4 2xl:px-8 py-2 border-2 ${borderColor} rounded-2xl shadow-lg`}
    >
      <span>{guide.name}</span>

      <div className="flex gap-2 items-center ">
        <button
          className="h-8 w-8 flex items-center justify-center"
          onClick={handleDownloadGuide}
        >
          <img src={DownloadIcon} alt="" />
        </button>
        <button className="h-8 w-8" onClick={addToSavedGuides}>
          {savedGuides.data?.data?.includes(guide.id) ? (
            <Bookmark className="text-yellow" />
          ) : (
            <BookmarkBorder />
          )}
        </button>
      </div>
    </li>
  );
}

export default GuideListItem;
