import React, {useContext, useEffect, useRef, useState} from "react";
import {generateMap, generateMapSearchBox} from "../../../../util/map";
import "./index.css";
import useExtremeIncidentsInTimerange from "./services/useExtremeIncidentsInTimerange";
import {IncidentDetailsModalContext} from "../../../../context/incident-details-modal";
import useFacilities from "../../../../services/useFacilities";
import IncidentDetails from "../../../../components/IncidentDetails";
import useTrackingHistory from "./hooks/useTrackingHistory";
import {useCustomOverlays, useGeofences, useZones,} from "../../../sites/services";
import {
  useCustomOverlayMarker,
  useFacilitiesMarker,
  useGeofencesMarker,
  useIncidentsMarker,
  useZonesMarker,
} from "../../../map/hooks";
import {FilterMapContext} from "../../context/filter-map";
import useUserLocationMarkers from "./hooks/useUserLocationMarkers";
import SearchFilterMap from "../SearchFilterMap";
import useWorldSearchMarkers from "./hooks/useWorldSearchMarkers";

function Map() {
  const {filters} = useContext(FilterMapContext);
  const {siteTypes, clientID} = filters;
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const incidentDetailsModalContext = useContext(IncidentDetailsModalContext)!;
  const {incidentDetails, incidentsModal} = incidentDetailsModalContext;

  const incidentsData: any = useExtremeIncidentsInTimerange().incidents;
  const {facilities: allFacilities} = useFacilities("facilities", {
    rpp: 200,
  });
  const {data: overlays} = useCustomOverlays().overlaysQuery;
  const {data: zones} = useZones().zonesQuery;
  const {data: geofences} = useGeofences().geofencesQuery;

  useEffect(() => {
    const zoom = 3;
    const center = [35.38464333951325, 17.370532026810842];
    const mapCenter = {lng: center[0], lat: center[1]};
    const generatedMap = generateMap(ref, mapCenter, zoom);
    setMap(generatedMap);
  }, []);

  useEffect(() => {
    if (!map) return;
    const operationalMapRouteLink = document.getElementById(
        "route-operational_map"
    );
    operationalMapRouteLink?.addEventListener("click", () => map.setZoom(3));
  }, [map]);

  useEffect(() => {
    const input = document.getElementById("pac-input") as HTMLInputElement;
    generateMapSearchBox(input, map);
  }, [map]);

  useIncidentsMarker(map, incidentsData);
  const userLocations = useTrackingHistory(map);
  useFacilitiesMarker(map, allFacilities, siteTypes, clientID);
  useCustomOverlayMarker(map, overlays, siteTypes, clientID);
  useZonesMarker(map, zones, siteTypes, clientID);
  useGeofencesMarker(map, geofences, siteTypes, clientID);
  useUserLocationMarkers(map);
  useWorldSearchMarkers(map);
  return (
      <>
        <SearchFilterMap userLocations={userLocations}/>
        <div ref={ref} className="h-full w-full"></div>
        {incidentDetails && incidentsModal && (
            <div
                className="z-10 absolute top-4 left-4 h-fit w-3/4 flex flex-col gap-8 overflow-y-auto px-8 bg-white items-center shadow-lg max-h-[60vh] max-w-xl rounded-lg">
              <IncidentDetails incident={incidentDetails}/>
            </div>
        )}
      </>
  );
}

export default Map;
