import React, { SetStateAction, useRef, useState } from "react";

import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import SendFollowUp from "../../components/SendFollowUp";
import FormStepOne from "./FormStepOne";

function FormSendNotification({
  sendNotificationOpen,
  setSendNotificationOpen,
}: {
  sendNotificationOpen: boolean;
  setSendNotificationOpen: React.Dispatch<SetStateAction<boolean>>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setSendNotificationOpen(false));
  const [formStep, setFormStep] = useState<1 | 2>(1);

  return (
    <div
      className={"absolute top-20 bg-white p-10 pt-4 overflow-hidden max-h-[600px]  overflow-y-scroll rounded-lg w-1/2"}
      ref={ref}
    >
      {formStep === 1 ? (
        <FormStepOne setFormStep={setFormStep} />
      ) : formStep === 2 ? (
        <SendFollowUp />
      ) : null}
    </div>
  );
}

export default FormSendNotification;
