import React, {useCallback, useEffect, useState} from "react";
import CheckInEntry from "./components/CheckInEntry";
// import { TripsContext } from "../TripsDataProvider";
import useGetCheckInsSheet from "../../../../api/check-ins-sheet/useGetCheckInsSheet";
import {CheckInType} from "../../types/check-ins-data-types";
import {useQueryClient} from "react-query";
import {ActionModalColored, AdditionalInfoModel, ModalLayout,} from "../../../../components/ui/Modals";
import {apiPATCH} from "../../../../apiv2/request";
import moment from "moment";


export default function UpcomingCheckIns() {
  const queryClient = useQueryClient();
  const {data: checkIns, status, error, refetch} = useGetCheckInsSheet();
  const [userEmail, setUserEmail] = useState<string>("");

  const [scheduledCheckInsAmount, setScheduledCheckInsAmount] = useState(0);
  const [upcomingCheckInsAmount, setUpcomingCheckInsAmount] = useState(0);
  const [lateCheckInsAmount, setLateCheckInsAmount] = useState(0);
  const [missedCheckInsAmount, setMissedCheckInsAmount] = useState(0);
  const [showChangeStatusPopUp, setShowChangeStatusPopUp] =
      useState<boolean>(false);
  const [showInfoPopUp, setShowInfoPopUp] =
      useState<boolean>(false);
  // const [checkIdToChangeStatus, setCheckIdToChangeStatus] =
  //     useState<string>("");


  const [checkInToUpdate, setCheckInToUpdate] = useState<CheckInType | undefined>();

  const [notesToChangeStatus, setNotesToChangeStatus] =
      useState<string>("");
  const [filteredCheckIns, setFilteredCheckIns] = useState<CheckInType[]>([]);
  const [displayCheckIns, setDisplayCheckIns] = useState<CheckInType[]>([]);
  const [notes, setNotes] = useState<string>("");
  const [notesCheck, setNotesCheck] = useState<string>("");

  const [saving, setSaving] = useState(false);

  const [showScheduled, setShowScheduled] = useState(false)
  const [showDue, setShowDue] = useState(true)
  const [showUpcoming, setShowUpcoming] = useState(true)
  const [showMissed, setShowMissed] = useState(true)


  useEffect(() => {
    if (status === "success" && checkIns) {
      const toDisplay: CheckInType[] = []

      if (showMissed) {
        toDisplay.push(...checkIns.filter((c) => isCurrentTimePastCheckInBy(c)))
      }

      if (showDue) {
        toDisplay.push(...checkIns.filter((c) => {
          return !isCurrentTimePastCheckInBy(c) && isCurrentTimePastCheckInAt(c)
        }))
      }

      if (showUpcoming) {
        toDisplay.push(...checkIns.filter((c) => {
          return !isCurrentTimePastCheckInBy(c) && !isCurrentTimePastCheckInAt(c) && isCheckInWithinOneHour(c)
        }))
      }

      if (showScheduled) {
        toDisplay.push(...checkIns.filter((c) => {
          return !isCurrentTimePastCheckInAt(c) && !isCurrentTimePastCheckInBy(c) && !isCheckInWithinOneHour(c)
        }))
      }

      setFilteredCheckIns(checkIns);
      setDisplayCheckIns(toDisplay)
    }
  }, [checkIns, status, showUpcoming, showScheduled, showDue, showMissed]);

  const isCheckInWithinOneHour = (checkIn: CheckInType): boolean => {
    const nowPlusOneHour = moment().add(1, "hour");
    const checkInAtDate = moment(checkIn.checkInAt!);

    return checkInAtDate.isBefore(nowPlusOneHour)
  };


  const isCurrentTimePastCheckInAt = (checkIn: CheckInType): boolean => {
    const now = new Date();
    const checkInAtDate = new Date(checkIn.checkInAt!);

    return now.getTime() > checkInAtDate.getTime();
  };

  const isCurrentTimePastCheckInBy = (checkIn: CheckInType): boolean => {
    const now = new Date();
    const checkInByDate = new Date(checkIn.checkInBy!);

    return now.getTime() > checkInByDate.getTime();
  };

  const calculateLateAndMissedCheckIns = () => {
    const now = new Date();
    const upcoming = filteredCheckIns.filter(c => {
      const nowPlusOneHour = moment().add(1, "hour")
      return moment(c.checkInAt).isBefore(nowPlusOneHour) && !isCurrentTimePastCheckInAt(c) &&
          !isCurrentTimePastCheckInBy(c)
    })


    const lateCheckIns = filteredCheckIns.filter(
        (checkIn) =>
            isCurrentTimePastCheckInAt(checkIn) &&
            !isCurrentTimePastCheckInBy(checkIn)
    );
    const missedCheckIns = filteredCheckIns.filter(isCurrentTimePastCheckInBy);

    return [upcoming.length, lateCheckIns.length, missedCheckIns.length];
  };

  useEffect(() => {
    // Define a function to update the check-in counts
    const updateCheckInCounts = () => {
      const [newUpcomingCheckInsAmount, newLateCheckInsAmount, newMissedCheckInsAmount] =
          calculateLateAndMissedCheckIns();

      setUpcomingCheckInsAmount(newUpcomingCheckInsAmount);
      setLateCheckInsAmount(newLateCheckInsAmount);
      setMissedCheckInsAmount(newMissedCheckInsAmount);
      if (checkIns) {
        setScheduledCheckInsAmount(checkIns.length - (newMissedCheckInsAmount + newUpcomingCheckInsAmount + newLateCheckInsAmount))
      }
    };

    // Call it once immediately
    updateCheckInCounts();

    // Then call it every minute
    const intervalId = setInterval(updateCheckInCounts, 60000);

    // Make sure to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [filteredCheckIns]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      clearInterval(interval)
    }


  }, []);

  useEffect(() => {
    if (status === "success" && checkIns) {
      setFilteredCheckIns(checkIns);
    }
  }, [checkIns, status]);

  const openChangeStatusPopUp = useCallback((checkIn: CheckInType, notes: string) => {
    if (checkIn) {
      setCheckInToUpdate(checkIn);
      setNotesToChangeStatus(notes);
      setShowChangeStatusPopUp(true);
    }
  }, []);

  const openInfoPopUp = useCallback((checkIn: CheckInType, userEmail: string, notesCheck: string) => {
    if (checkIn) {
      setCheckInToUpdate(checkIn);
      setUserEmail(userEmail);
      setNotesCheck(notesCheck);
      setShowInfoPopUp(true);
    }
  }, []);

  const changeStatus = async () => {
    if (!checkInToUpdate) {
      return
    }

    setSaving(true)
    try {
      await apiPATCH(`${process.env.REACT_APP_API_V2_URL}/trips/${checkInToUpdate.tripID}/scheduled-check-ins/${checkInToUpdate.id}/resolve`, {notes})
      setShowChangeStatusPopUp(false);
    } catch (e) {
      console.error(e)
    }

    setSaving(false)


    await queryClient.invalidateQueries("check-ins-sheet");

  };

  // const changeNotes = () => {
  //   api.patch(`ops/update-check-in-notes/${checkIdToChangeStatus}`, {notes: notesCheck})
  //       .then((res) => {
  //         console.log("Updated the notes for the check-in");
  //         queryClient.invalidateQueries("check-ins-sheet");
  //         setShowInfoPopUp(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // };

  const closeModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowChangeStatusPopUp(false);
      setShowInfoPopUp(false);
    }
  };

  const resetFilter = () => {
    setShowUpcoming(true)
    setShowDue(true)
    setShowScheduled(true)
    setShowMissed(true)
  }


  return (
      <div className="pt-32 pb-16">
        <div className="flex w-full justify-between items-center px-12 pb-4">
          <span>Check In Sheet</span>
        </div>


        <div className="w-full bg-white h-full pt-6 pb-20 px-12">

          <div className={"grid xs:grid-cols-1 sm:grid-cols-5 text-center font-semibold gap-4"}>
            <button
                onClick={resetFilter}
                className={`bg-blue rounded-2xl p-4 text-white ${!showUpcoming || !showScheduled || !showDue || !showMissed ? "opacity-20" : ""}`}>
              <div className={"text-lg"}>All</div>
              <div className={"text-2xl"}>{filteredCheckIns.length}</div>
            </button>

            <button
                onClick={() => setShowUpcoming(!showUpcoming)}
                className={`bg-dark-green rounded-2xl p-4 text-white ${!showUpcoming ? "opacity-20" : ""}`}>
              <div className={"text-lg"}>Upcoming</div>
              <div className={"text-2xl"}>{upcomingCheckInsAmount}</div>
            </button>

            <button
                onClick={() => setShowDue(!showDue)}
                className={`bg-dark-orange rounded-2xl p-4 text-white ${!showDue ? "opacity-20" : ""}`}>
              <div className={"text-lg"}>Due</div>
              <div className={"text-2xl"}>{lateCheckInsAmount}</div>
            </button>

            <button
                onClick={() => setShowMissed(!showMissed)}
                className={`bg-red rounded-2xl p-4 text-white ${!showMissed ? "opacity-20" : ""}`}>
              <div className={"text-lg"}>Missed</div>
              <div className={"text-2xl"}>{missedCheckInsAmount}</div>
            </button>


            <button
                onClick={() => setShowScheduled(!showScheduled)}
                className={`bg-grey rounded-2xl p-4 text-white ${!showScheduled ? "opacity-20" : ""}`}>
              <div className={"text-lg"}>Scheduled</div>
              <div className={"text-2xl"}>{scheduledCheckInsAmount}</div>
            </button>


          </div>

          <div className="mt-4">
            <div className="">
              <div className="w-full gap-4 flex flex-col">
                {status === "error" && (
                    <div className="w-full text-center">Try again later</div>
                )}
                {status === "loading" && (
                    <div className="w-full text-center">Loading...</div>
                )}
                {status === "success" && checkIns === null && (
                    <div className="w-full text-center">No results</div>
                )}
                {status === "success" &&
                    displayCheckIns !== null &&
                    displayCheckIns.map((checkIn: CheckInType) => {
                      return (
                          <CheckInEntry
                              key={checkIn.id}
                              data={checkIn}
                              openStatusChangePopUp={() => {
                                openChangeStatusPopUp(checkIn, checkIn.notes);
                              }}
                              openStatusInfoPopUp={() => {
                                openInfoPopUp(checkIn, checkIn.userEmail ? checkIn.userEmail : "", checkIn.notes ? checkIn.notes : "");
                              }}
                              checkInUpcoming={isCheckInWithinOneHour(checkIn)}
                              checkInSoon={isCurrentTimePastCheckInAt(checkIn)}
                              checkInPassed={isCurrentTimePastCheckInBy(checkIn)}
                          />
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        {showChangeStatusPopUp && (
            <ModalLayout close={closeModal}>
              <ActionModalColored
                  color="green"
                  buttonLabel="Check In"
                  mainButton={changeStatus}
                  closeButton={closeModal}
                  showButton={true}
                  loading={saving}
              >
                <div className="font-bold">Notes</div>
                <div className="pb-5">
              <textarea
                  name=""
                  id=""
                  cols={25}
                  rows={5}
                  className="border outline-none py-2 px-4 bg-desaturated-grey border-none rounded-lg w-full overflow-hidden resize-none"
                  onChange={(e) => {
                    setNotes(e.target.value)
                    setNotesToChangeStatus(e.target.value)
                  }}
                  placeholder={"Enter notes here..."}
                  value={notesToChangeStatus}
              />
                </div>
              </ActionModalColored>
            </ModalLayout>
        )}
        {showInfoPopUp && (
            <ModalLayout close={closeModal}>
              <AdditionalInfoModel
                  color="blue"
                  buttonLabel="Save"
                  mainButton={() => {
                  }}
                  closeButton={closeModal}
                  showButton={false}
                  userEmail={userEmail}
              >
                <div className="font-bold">Email: {userEmail}</div>
                <div className="font-bold">Notes:</div>
                <div className="pb-5">
              <textarea
                  name=""
                  id=""
                  cols={25}
                  rows={5}
                  readOnly
                  className="border outline-none py-2 px-11 border-none rounded-lg w-full resize-none overflow-auto"
                  value={notesCheck}
              />
                </div>
              </AdditionalInfoModel>
            </ModalLayout>
        )}

      </div>
  );
}
