import React from "react";
import Label from "../../../../../components/form/Label";

import UserTypeListItem from "./UserTypeListItem";
import { UserTypeLists } from "../../../types/user-type-list-types";
function UserType({selected, onChange}: {selected: string[], onChange: (values: string[]) => void}) {


  const onItemChange = (key: string, value: boolean) => {

    console.log(key, value)
    if (value) {
      onChange([...selected, key])
      return
    }

    const index = selected.indexOf(key);
    onChange( [...selected.slice(0, index), ...selected.slice(index + 1)])

  }

  return (
    <div className="flex flex-col gap-2">
      <Label text="User Type" htmlFor={"user-type"} />

      <div className="flex flex-col gap-2">
        {UserTypeLists.map((item) => (
          <UserTypeListItem key={item.value} item={item} selected={selected.includes(item.value)} onChange={onItemChange}/>
        ))}
      </div>
    </div>
  );
}

export default UserType;
