import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import DarkRedDotIcon from "../../../../../../assets/icons/dark-red-dot.svg";
import { CountryProfileContext } from "../../context/country-profile";
import useCountryNameToGeoCode from "../../../../../../hooks/useCountryNameToGeoCode";
import { IncidentDataTypes } from "../../../../../../types/incident-data-types";
import { generateCircleRadius, generateMap } from "../../../../../../util/map";
import { CountryProfileDataTypes } from "../../../../../../types/country-profile-data-types";
import useGetIncidentsPerCountry from "../../../../../../api/incidents/useGetIncidentsPerCountry";
import IncidentDetails from "../../../../../../components/IncidentDetails";
import { IncidentDetailsModalContext } from "../../../../../../context/incident-details-modal";
type MapPropTypes = {
  zoom: number;
  incidents: IncidentDataTypes[];
  countryProfileData: CountryProfileDataTypes;
};

function Map({ zoom = 2, incidents, countryProfileData }: MapPropTypes) {
  const {
    setIncidentDetails,
    setIncidentsModal,
    incidentDetails,
    incidentsModal,
  } = useContext(IncidentDetailsModalContext)!;

  const ref = useRef<HTMLDivElement>(null);

  const center: { lat: number; lng: number } = useCountryNameToGeoCode(
    countryProfileData.country
  )!;

  const handleMarkerClick = (lng: number, lat: number) => {
    const markerIncident: IncidentDataTypes | undefined = incidents.find(
      (incident: IncidentDataTypes) =>
        incident.position.coordinates[0] === lng &&
        incident.position.coordinates[1] === lat
          ? incident
          : null
    );

    if (!markerIncident || markerIncident === undefined) {
      return console.error("Failed to get this incident.");
    } else {
      setIncidentDetails(markerIncident);
      setIncidentsModal(true);
    }
  };

  const getIncident = (position: { lat: number; lng: number }) => {
    const { lat, lng } = position;
    const markerIncident = incidents.filter((incident: IncidentDataTypes) =>
      incident.position.coordinates[0] === lng &&
      incident.position.coordinates[1] === lat
        ? incident
        : null
    );

    if (!markerIncident) return;
    // setIncidentDetails(markerIncident);
  };

  const getMarkerPosition = (markerLng: number, markerLat: number) => {
    // setIncidentDetailsOpen(true);
    getIncident({ lat: markerLat, lng: markerLng });
    // incidentModalsContext?.setIncidentsModal(true);
  };

  useEffect(() => {
    /**
     * This below initialises the map
     */
    if (!center) return;
    const map = generateMap(ref, center!, zoom);

    /**
     * This below renders marker on map for each incident
     */
    const markers = incidents.map((incident: IncidentDataTypes) => {
      const lng = incident.position.coordinates[0];
      const lat = incident.position.coordinates[1];
      // this below is where a marker will be placed and an icon representing it
      const marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        icon: DarkRedDotIcon,
        clickable: true,
      });

      const markerLat = marker.getPosition()?.lat();
      const markerLng = marker.getPosition()?.lng();

      // this below is a circle radius around a marker
      const cityCircle = generateCircleRadius(map, incident.severity, lat, lng);

      // cityCircle.addListener("click", () => {
      //   getMarkerPosition(markerLat!, markerLng!);
      // });

      cityCircle.addListener("click", () => {
        handleMarkerClick(markerLng!, markerLat!);
      });
      return marker;
    });

    /**
     * This below is used to cluster the markers together.
     * As the user zooms in the cluster will split and as the user
     * zooms out the cluster will join.
     */

    const markerClusterer = new MarkerClusterer({
      markers,
      map,
      onClusterClick: () => {},
    });
  }, [center]);

  return (
    <>
      <div ref={ref} className="h-full w-full"></div>

      {incidentDetails && incidentsModal && (
        <div className="z-10 absolute top-4 left-10 h-fit w-3/4 flex flex-col gap-8 overflow-y-auto px-8 bg-white items-center shadow-lg max-h-[60vh] max-w-xl rounded-lg">
          <IncidentDetails incident={incidentDetails} />
        </div>
      )}
    </>
  );
}

export default Map;
