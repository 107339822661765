import React, {FormEvent, SetStateAction, useContext, useEffect, useRef, useState,} from "react";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import Sites from "./components/Sites";
import UserType from "./components/UserType";
import {BlueButton} from "../../../../components/ui/Buttons";
import Label from "../../../../components/form/Label";
import {ClientDataTypes} from "../../../../types/client-data-types";
import useGetAllClients from "../../../../api/clients/useGetAllClients";
import Checkbox from "../../../../components/form/Checkbox";
import {FilterMapContext} from "../../context/filter-map";
import {UserContext} from "../../../../context/user";
import ClientSelect from "../../../../components/form-v2/searchable-select/ClientSelect";
import {Client} from "../../../../types/userManagement";
import WorldSearchType from "./components/WorldSearchType";

function FormFilterOperationalMap({
                                    setFilterOpen,
                                  }: {
  filterOpen: boolean;
  setFilterOpen: React.Dispatch<SetStateAction<boolean>>;
}) {


  const {role} = useContext(UserContext)!;

  const {setFilters, filters, client, setClient} = useContext(FilterMapContext);
  const defaultOperationalMapFilters: {
    clientID: string;
    userTypes: string[];
    siteTypes: string[];
    worldSearchTypes: string[];
    trackingUserID: string | null;
    incidents: boolean;
    searchOverlayID: null | string;
  } = JSON.parse(localStorage.getItem("operational-map-filter-config") as string);

  defaultOperationalMapFilters.clientID = ""

  const userLoggedIn = JSON.parse(localStorage.getItem("user") as string);

  const formRef = useRef<HTMLFormElement>(null);
  const [isIncidents, setIsIncidents] = useState<boolean>(
      defaultOperationalMapFilters.incidents
  );
  const [defaultClient, setDefaultClient] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const {all_clients_data} = useGetAllClients();
  const clientOptions: { value: string; label: string }[] =
      all_clients_data.map((client: ClientDataTypes) => ({
        label: client.name,
        value: client.id,
      }));
  if (role.auroraAccessLevel === "all") {
    clientOptions.unshift({label: "All Clients", value: "all"});
  }

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setFilterOpen(false));

  useEffect(() => {
    const clientOptionSelected = clientOptions.find(
        (client) => client.value === defaultOperationalMapFilters.clientID
    );
    if (clientOptionSelected) {
      setDefaultClient(clientOptionSelected);
    }
  }, []);

  const handleFilter = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const overlayID = formData.get("overlay") as string;
    const siteTypes = formData.getAll("sites_type") as string[];

    setClient(_client)


    const filter = {
      clientID: _client?.id || "",
      userTypes: userTypes,
      siteTypes,
      trackingUserID: filters.trackingUserID,
      incidents: isIncidents,
      searchOverlayID: overlayID || null,
      worldSearchTypes,
    };


    setFilters(filter);
    setFilterOpen(false);
  };

  const [userTypes, setUserTypes] = useState<string[]>(defaultOperationalMapFilters.userTypes)
  const [worldSearchTypes, setWorldSearchTypes] = useState<string[]>(defaultOperationalMapFilters.worldSearchTypes)

  useEffect(() => {
    console.log({worldSearchTypes})
  }, [worldSearchTypes]);


  const handleReset = (e: FormEvent) => {
    e.preventDefault();
    const defaultFilters = {
      clientID: "",
      userTypes: filters.trackingUserID
          ? []
          : ["app", "trip", "facility", "satellite"],
      worldSearchTypes: [],
      siteTypes: [],
      trackingUserID: filters.trackingUserID,
      incidents: true,
      searchOverlayID: null,
    };

    setClient(null)

    setFilters(defaultFilters);
    setFilterOpen(false);
  };

  const [_client, _setClient] = useState<Client | null>(client)


  return (
      <div
          className={"absolute top-20 bg-white h-fit overflow-y-auto max-h-fit p-4 text-sm rounded-lg shadow-lg max-w-md w-full"}
          ref={ref}
      >
        <form className="flex flex-col gap-4 pointer-events-auto" ref={formRef}>
          <div className="mx-auto">
            <Label text="Filter Operational Map"/>
          </div>
          <hr/>

          {role.auroraAccessLevel == "all" ?
              <ClientSelect multiple={false} clients={_client ? [_client] : []} onSelectionChange={(clients) => {
                _setClient(clients.length > 0 ? clients[0] : null)
              }}/> : null}


          <UserType selected={userTypes} onChange={(selected) => {
            setUserTypes(selected)
          }}/>

          <WorldSearchType selected={worldSearchTypes || []} onChange={(selected) => {
            console.log(selected)
            setWorldSearchTypes(selected)
          }}/>

          <Sites/>
          <div className="flex flex-col gap-2">
            <Label text="Incidents"/>
            <div className={`${isIncidents ? null : "opacity-50"}`}>
              <Checkbox
                  label="Extreme"
                  name="incidents"
                  value="incidents"
                  checked={isIncidents}
                  onClick={() => setIsIncidents((prev) => !prev)}
              />
            </div>
          </div>

          <div className="px-20 my-6 flex gap-4">
            <BlueButton type="button" text="Filter" onClick={handleFilter}/>
            <BlueButton type="button" text="Reset" onClick={handleReset}/>
          </div>
        </form>
      </div>
  );
}

export default FormFilterOperationalMap;
