/**
 * @description restricts Setting links based on logged in user role
 * @returns an array of allowed links filtered based on user role
 */
import { useContext } from "react";
import { UserContext } from "../context/user";
export default function useRestrictedSettings() {
  const user = useContext(UserContext);
  const roleName = user?.role.name;
  const links = [
    {
      slug: "country-profiles",
      label: "Country Profiles",
      path: "/settings/country-profiles",
      rolesAllowed: ["NGS Super Admin"],
    },
    {
      slug: "custom-emails-recipients",
      label: "Custom Emails",
      path: "/settings/custom-emails-recipients",
      rolesAllowed: [
        "Client Global Admin",
        "NGS Super Admin",
        "Department Admin",
      ],
    },
    {
      slug: "airline-risk-matrix",
      label: "Airline Risk Matrix",
      path: "/settings/airline-risk-matrix",
      rolesAllowed: [
        "NGS Super Admin",
      ],
    }
  ];

  const filteredLinks = links.filter((link) =>
    link.rolesAllowed.includes(roleName!)
  );

  return filteredLinks;
}
