import React, {createContext, useContext, useEffect, useState} from "react";
import {
  CheckInResponse,
  ItineraryItem,
  ItineraryItemSubtype,
  ItineraryItemType,
  ItineraryLocation,
  ItineraryTime,
  Trip,
  TripRequest
} from "./types";
import {UserDataTypes} from "../../types/user-data-types";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {apiGET, apiPOST, apiPUT} from "../../apiv2/request";
import moment from "moment-timezone";
import {v4} from "uuid";
import api from "../../api/axiosConfig";
import {PaginatedResponse} from "../../types/PaginatedResponse";
import {metadataFields} from "./metadata";
import {UserContext} from "../../context/user";
import {useNavigate} from "react-router-dom";
import {Moment} from "moment";


// Define the type for the context value
interface TripFormContextType {
  id: string | undefined
  mode: string
  itinerary: ItineraryItem[];
  setItinerary: React.Dispatch<React.SetStateAction<ItineraryItem[]>>;
  user: UserDataTypes | undefined
  setUser: React.Dispatch<React.SetStateAction<UserDataTypes | undefined>>;
  checkInType: string,
  setCheckInType: React.Dispatch<React.SetStateAction<string>>;
  checkInTimes: string[]
  setCheckInTimes: React.Dispatch<React.SetStateAction<string[]>>
  checkInGracePeriod: number;
  setCheckInGracePeriod: React.Dispatch<React.SetStateAction<number>>;
  page: Page,
  setPage: (page: Page) => Promise<void>;
  errors: string[],
  localErrors: string[],
  setErrors: React.Dispatch<React.SetStateAction<string[]>>
  buildRequest: () => TripRequest
  submitTrip: () => Promise<void>
  previewCheckIns: CheckInResponse | undefined
  allowCheckInConfig: boolean
  getCheckInPreview: () => Promise<void>
  saving: boolean,
}

// Create a context
const TripFormContext = createContext<TripFormContextType | undefined>(undefined);

type TripFormProviderProps = {
  id?: string
  mode: "edit" | "create"
}

type Page = "trip" | "check_ins"


// Create a provider component
export const TripFormProvider: React.FC<React.PropsWithChildren<TripFormProviderProps>> = (props) => {
  const {role} = useContext(UserContext)!;
  const navigate = useNavigate();


  const id = props.id
  const mode = props.mode
  const [itinerary, setItinerary] = useState<ItineraryItem[]>([]);
  const [user, setUser] = useState<UserDataTypes | undefined>()

  const [checkInType, setCheckInType] = React.useState<string>("auto")
  const [checkInTimes, setCheckInTimes] = React.useState<string[]>([])
  const [checkInGracePeriod, setCheckInGracePeriod] = React.useState<number>(60)
  const [previewCheckIns, setPreviewCheckIns] = React.useState<CheckInResponse | undefined>()

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<Page>("trip");
  const [errors, setErrors] = useState<string[]>([])
  const [localErrors, setLocalErrors] = useState<string[]>([])

  const [userEditable, setUserEditable] = useState<boolean>(true)

  const [allowCheckInConfig, setAllowCheckInConfig] = useState(false)

  const [saving, setSaving] = useState(false)


  const getCheckInPreview = async (force?: boolean) => {

    if (!force && page != "check_ins") {
      return
    }
    setLoading(true)
    const res = await fetchCheckInPreview(allowCheckInConfig ? checkInType : "auto")
    setPreviewCheckIns(res)
    setLoading(false)
  }

  const fetchCheckInPreview = async (type?: string) => {
    return await apiPOST<CheckInResponse>(`${process.env.REACT_APP_API_V2_URL}/scheduled-check-ins/preview`, buildRequest(type))
  }

  useEffect(() => {


    if (user && !userEditable) {
      handleSetPage("check_ins")
    }
  }, [userEditable, user])


  const fetchInitialUser = async (id: string) => {
    try {
      const res = await api.get<PaginatedResponse<UserDataTypes>>("/users", {
        params: {ids: id},
      });
      setUser(res.data.items[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const buildRequest = (mode?: string) => {
    const request: TripRequest = {
      checkInConfig: {
        mode: mode || checkInType,
        times: checkInTimes,
        gracePeriod: checkInGracePeriod
      },
      itineraryItems: itinerary.map(i => {

        const details: Record<string, string> = {}
        const fields = metadataFields[i.subtype || ""] || []

        fields.forEach((f) => {
          details[f] = i.details[f] || ""
        })

        i.details = details


        if (i.type == "travel") {
          i.startTime.timezone = i.locations[0].timezone
          i.endTime.timezone = i.locations[i.locations.length - 1].timezone
        } else {
          i.startTime.timezone = i.locations[0].timezone
          i.endTime.timezone = i.locations[0].timezone
        }
        return i
      }),
      userID: user?.id
    }
    return request
  }

  const submitTrip = async () => {

    setSaving(true)

    if (role.auroraAccessLevel != "user" && !user) {
      setErrors(["no user selected"])
      return
    }


    const request = buildRequest(allowCheckInConfig ? checkInType : "auto")


    try {
      switch (mode) {
        case "create":
          await apiPOST(`${process.env.REACT_APP_API_V2_URL}/trips`, request)
          break;
        case "edit":
          await apiPUT(`${process.env.REACT_APP_API_V2_URL}/trips/${id}`, request)
      }
      navigate("/trips")
    } catch (e) {
      const err = e as { errors?: string[] }

      if (err.errors) {
        setErrors(err.errors)
      }
    }

    setSaving(false)


  }


  const load = async () => {
    const url = `${process.env.REACT_APP_API_V2_URL}/trips/${id}`
    const result = await apiGET<Trip>(url, {}, {})

    await fetchInitialUser(result.userID)

    if (result.checkInSettings.types.length == 1) {
      setCheckInType(result.checkInSettings.types[0])
      setCheckInTimes(result.checkInSettings.times)
      setCheckInGracePeriod(result.checkInSettings.gracePeriod || 60)

    }

    setUserEditable(result.settings.userEditable)


    setItinerary(result.itinerary.map(i => {

      const startMoment = moment(i.startTime, "YYYY-MM-DDTHH:mm:ssZ")
      const endMoment = moment(i.endTime, "YYYY-MM-DDTHH:mm:ssZ")


      const item: ItineraryItem = {
        details: i.details,
        startTime: {
          time: startMoment.tz(i.locations[0].timezone).format("HH:mm"),
          date: startMoment.tz(i.locations[0].timezone).format("YYYY-MM-DD"),
          timezone: "Europe/London"
        },
        endTime: {
          time: endMoment.tz(i.locations[i.locations.length - 1].timezone).format("HH:mm"),
          date: endMoment.tz(i.locations[i.locations.length - 1].timezone).format("YYYY-MM-DD"),
          timezone: "Europe/London"
        },
        id: v4(),
        locations: i.locations.map((l) => {
          const location: ItineraryLocation = {
            addressLines: l.addressLines,
            countryISO: l.countryISO,
            county: l.county,
            latitude: l.point.coordinates[1],
            longitude: l.point.coordinates[0],
            postalCode: l.postalCode,
            region: l.region,
            timezone: l.timezone,
            town: l.town
          }
          return location
        }) as [ItineraryLocation],

        subtype: i.subType as ItineraryItemSubtype,
        type: i.type as ItineraryItemType

      }

      return item
    }))

    setLoading(false)

  }
  useEffect(() => {
    if (id) {
      load()
    } else {
      setLoading(false)
    }
  }, []);


  const validateTravel = (item: ItineraryItem, index: number): string[] => {
    const errors: string[] = []

    if (!item.startTime.time) {
      errors.push(`item ${index + 1} missing departure time`)
    }
    if (!item.startTime.date) {
      errors.push(`item ${index + 1} missing departure date`)
    }

    if (!item.locations[0].timezone) {
      errors.push(`item ${index + 1} missing departure timezone`)
    }

    if (!item.locations[0].countryISO) {
      errors.push(`item ${index + 1} missing origin country`)
    }

    if (!item.locations[0].town) {
      errors.push(`item ${index + 1} missing origin town`)
    }

    if (!item.endTime.time) {
      errors.push(`item ${index + 1} missing arrival time`)
    }
    if (!item.endTime.date) {
      errors.push(`item ${index + 1} missing arrival date`)
    }

    if (!item.locations[1]?.timezone) {
      errors.push(`item ${index + 1} missing arrival timezone`)
    }


    if (!item.locations[1]?.countryISO) {
      errors.push(`item ${index + 1} missing destination country`)
    }

    if (!item.locations[1]?.town) {
      errors.push(`item ${index + 1} missing destination town`)
    }


    return errors
  }

  const validateAccommodation = (item: ItineraryItem, index: number): string[] => {
    const errors: string[] = []

    if (!item.startTime.time) {
      errors.push(`item ${index + 1} missing arrival time`)
    }
    if (!item.startTime.date) {
      errors.push(`item ${index + 1} missing arrival date`)
    }

    if (!item.endTime.time) {
      errors.push(`item ${index + 1} missing departure time`)
    }
    if (!item.endTime.date) {
      errors.push(`item ${index + 1} missing departure date`)
    }

    if (!item.locations[0].timezone) {
      errors.push(`item ${index + 1} missing timezone`)
    }

    if (!item.locations[0].countryISO) {
      errors.push(`item ${index + 1} missing country`)
    }

    if (!item.locations[0].town) {
      errors.push(`item ${index + 1} missing town`)
    }

    return errors
  }

  const validate = () => {
    const errors: string[] = []

    if (!user && role.auroraAccessLevel != "user") {
      errors.push("no user selected")
    }

    itinerary.forEach((item, index) => {
      if (item.type == "travel") {
        errors.push(...validateTravel(item, index))
      }

      if (item.type == "accommodation") {
        errors.push(...validateAccommodation(item, index))

      }
    })

    const parseTime = (time: ItineraryTime): Moment => {
      return moment.tz(`${time.date} ${time.time}`, "YYYY-MM-DD HH:mm", time.timezone)
    }


    for (let i = 0; i < itinerary.length; i++) {
      const prev = i != 0 ? itinerary[i - 1] : null
      const current = itinerary[i]

      const start = parseTime(current.startTime)
      const end = parseTime(current.endTime)

      if (prev) {
        const prevEnd = parseTime(prev.endTime)
        if (start.isBefore(prevEnd)) {
          errors.push(`itinerary item ${i + 1} has an start time before the previous items end time`)
        }
      }

      if (end.isBefore(start)) {
        errors.push(`itinerary item ${i + 1} has an end time before it's start time`)
      }
    }

    return errors

  }

  const handleSetPage = async (p: Page) => {
    console.log(p)
    if (p == "trip") {
      setPreviewCheckIns([])
      setPage(p)
      return
    }


    const errors = validate()


    if (errors.length != 0) {
      setLocalErrors(errors)
      return
    } else {
      setLocalErrors([])
    }

    setLoading(true)
    const res = await fetchCheckInPreview("auto")


    if (!res || res.length == 0) {
      setAllowCheckInConfig(true)
    } else {
      setAllowCheckInConfig(false)
      setPreviewCheckIns(res)
    }

    setLoading(false)
    setPage(p)
  }


  return (
      <TripFormContext.Provider value={{
        id,
        mode,
        itinerary, setItinerary,
        user, setUser,
        checkInType, setCheckInType,
        checkInTimes, setCheckInTimes,
        checkInGracePeriod, setCheckInGracePeriod,
        page,
        setPage: handleSetPage,
        errors,
        localErrors,
        setErrors,
        buildRequest,
        submitTrip,
        previewCheckIns,
        allowCheckInConfig,
        getCheckInPreview,
        saving
      }}>
        {loading ? <div className={"pt-28 pb-40 h-screen w-screen justify-center items-center flex absolute"}>
          <LoadingSpinner/>
        </div> : <div className="w-full h-full p-36 flex gap-8">{props.children}</div>}
      </TripFormContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useTripFormContext = (): TripFormContextType => {
  const context = useContext(TripFormContext);
  if (!context) {
    throw new Error("useTripFormContext must be used within a TripFormProvider");
  }
  return context;
};