import React, { useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import Label from "../../../../../components/form/Label";
import Search from "../../../../../components/form/Search";
import { WhiteButton } from "../../../../../components/ui/Buttons";

function StepOne() {
  const [addAllTravellers, setAddAllTravellers] = useState<boolean>(false);
  return (
    <div>
      <div className="flex items-center justify-center">
        <Label
          text="Step 1:"
          assignText="Select people to receive notification"
        />
      </div>

      <div className="grid grid-cols-3 items-center justify-center px-10 mt-6">
        <div className="flex flex-col gap-4">
          <Search placeholder="Search groups" />
          <Checkbox
            label="Add all travellers"
            name="add-all-travellers"
            value="add-all-travellers"
            checked={addAllTravellers}
            onClick={() => setAddAllTravellers((prev) => !prev)}
          />
        </div>

        <div className="flex flex-col relative overflow-hidden text-center  py-6">
          <hr className="absolute h-[1px] w-full rotate-90 opacity-50" />
          <span className="relative text-sm z-10 bg-white">and</span>
          <span className="relative text-sm z-10 bg-white">or</span>
        </div>

        <div className="flex flex-col gap-2 text-left">
          <WhiteButton text="Draw on Map" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}

export default StepOne;
