import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import SeeLatestIncidents from "./SeeLatestIncidents";
import Flag from "./Flag";
import { UsersType } from "../../../../../../../../context/country-profiles";
import useGetRiskKey from "../../../../hooks/useGetRiskKey";
import {
  BookmarkButtonWhiteOutline,
  DownloadButtonWhiteOutline,
} from "../../../../../../../../components/ui/Buttons";
import { CountryProfileContext } from "../../../../context/country-profile";

type HeaderTypes = {
  data: UsersType;
};
function Header() {
  const { id } = useParams();

  const countryProfileContext = useContext(CountryProfileContext);

  const key = useGetRiskKey(countryProfileContext!.data?.data.averageRiskLevel);

  const { color } = key![0];
  const CountryName = () => (
    <h4 className="text-lg ml-16 flex-1 ">
      {countryProfileContext!.data?.data.country}
    </h4>
  );

  const IncidentsCount = () => (
    <div
      className="h-8 w-8 rounded-full bg-white shadow-xl flex items-center justify-center text-sm"
      style={{ color: color }}
    >
      <span>{countryProfileContext!.data?.data.averageRiskLevel}</span>
    </div>
  );

  return (
    <div className="flex gap-4 max-w-[1080px] w-full mx-auto p-8">
      <div
        className={"text-white font-semibold h-12 flex-1 rounded-xl flex items-center px-6 w-full"}
        style={{ backgroundColor: color }}
      >
        <Flag country_code={countryProfileContext!.data?.data.countryISO!} />
        <CountryName />
        <div className="flex gap-4">
          <IncidentsCount />
          {/* <DownloadButtonWhiteOutline />
          <BookmarkButtonWhiteOutline /> */}
        </div>
      </div>
      <SeeLatestIncidents color={color} />
    </div>
  );
}

export default Header;
