import { useInfiniteQuery } from "react-query";
import api from "../api/axiosConfig";
import { useEffect, useState } from "react";
import { IncidentDataTypes } from "../types/incident-data-types";

export default function useIncidents(querykey: string, params: {} = {}) {
  const [allIncidents, setAllIncidents] = useState<IncidentDataTypes[]>([]);
  const fetchIncidents = async (page: number) => {
    const response = await api.get(`/incidents?page=${page}`, { params });
    return response.data;
  };

  const { data, fetchNextPage, hasNextPage, isFetching, status, isLoading } =
    useInfiniteQuery(
      querykey,
      ({ pageParam = 1 }) => fetchIncidents(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.hasMore) {
            return allPages.length + 1;
          }
          return undefined;
        },
      }
    );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (hasNextPage) return;
    const flatItems = data ? data.pages.flatMap((page) => page.items) : [];
    setAllIncidents(flatItems);
  }, [data, hasNextPage]);

  return {
    incidents: allIncidents,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status,
    isLoading,
  };
}
