import { useQuery } from "react-query";
import api from "../axiosConfig";

export default function useGetReportsSummary(
  startTime: string,
  endTime: string,
  clientIDs: string[],
  enabled: boolean
) {
  const fetchReportsSummary = async () => {

    const end = new Date(endTime)
    end.setHours(23,59,59,999)

    return await api
      .get("/reports/summary", {
        params: {
          startTime,
          endTime: end.toISOString(),
          clientIDs: clientIDs.join(),
        },
      })
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  const { data, status, error, refetch } = useQuery(
    "reports-summary",
    fetchReportsSummary,
    {
      enabled,
    }
  );

  return {
    data,
    status,
    error,
    refetch,
  };
}
