import React, {memo, useCallback, useMemo} from "react";
import {dateToHoursAndMinutes} from "../../../../../util/helpers";
import {CheckInType} from "../../../types/check-ins-data-types";
import moment from "moment";
import {BlueButton, GreenButton, OrangeButton, RedButton} from "../../../../../components/ui/Buttons";
import {startCase} from "lodash";
import {countries} from "../../../../../constants/countries";

const CheckInEntry = ({
                                       data,
                                       checkInSoon,
                                       checkInPassed,
                                       openStatusChangePopUp,
                                       openStatusInfoPopUp,
                                       checkInUpcoming,
                                     }: {
  data: CheckInType;
  openStatusChangePopUp: (id: string) => void;
  openStatusInfoPopUp: (id: string) => void;
  checkInUpcoming: boolean;
  checkInSoon: boolean;
  checkInPassed: boolean;
}) => {
  const gracePeriodInMinutes = useCallback((checkIn: CheckInType): number => {
    const start = new Date(checkIn.checkInAt!);
    const end = new Date(checkIn.checkInBy!);
    const differenceInMilliseconds = end.getTime() - start.getTime();
    return differenceInMilliseconds / (1000 * 60);
  }, []);

  const formatTimeToTimeZone = useCallback((date: moment.MomentInput, timezone: string) => {
    if (!date) return "-";
    if (!timezone) return moment(date).format("HH:mm");
    return moment(date).tz(timezone).format("HH:mm");
  }, []);


  const formatDateToTimeZone = useCallback((date: moment.MomentInput, timezone: string) => {
    if (!date) return "-";
    if (!timezone) return moment(date).format("DD/MM/YYYY");
    return moment(date).tz(timezone).format("DD/MM/YYYY");
  }, []);

  const bgClass = useMemo(() => {
    if (checkInPassed) return "bg-mid-red";
    if (checkInSoon) return "bg-mid-orange";
    if (checkInUpcoming) return "bg-desaturated-green";
    return "bg-desaturated-grey";
  }, [checkInPassed, checkInSoon, checkInUpcoming]);

  const textClass = useMemo(() => {
    if (checkInPassed) return "text-dark-red";
    if (checkInSoon) return "text-dark-orange";
    if (checkInUpcoming) return "text-dark-green";
    return "";
  }, [checkInPassed, checkInSoon, checkInUpcoming]);

  const phoneNumbers = useMemo(() => (
      data?.userPhoneNumbers?.filter((p) => p.number.trim()).map(p => p.number) || []
  ), [data?.userPhoneNumbers]);

  const parseType = useCallback((checkInType: string) => {
    if (checkInType.includes("travel") || checkInType.includes("accommodation")) {
      return checkInType.split(":").map(p => startCase((p))).slice(1, 3).join(" – ");
    }

    if (checkInType.includes("manual")) {
      const firstColonIndex = checkInType.indexOf(":");
      const secondColonIndex = checkInType.indexOf(":", firstColonIndex + 1);

      const parts = [
        checkInType.substring(0, firstColonIndex),
        checkInType.substring(firstColonIndex + 1, secondColonIndex),
        checkInType.substring(secondColonIndex + 1)
      ];

      const country = countries.find((c) => c.code == parts[1])?.name || parts[1];
      parts[2] = parts[2].split("T").join(" ");
      return `${country} – ${parts[2]}`;
    }

    if (checkInType.includes("set_time")) {
      const firstColonIndex = checkInType.indexOf(":");

      const parts = [
        checkInType.substring(0, firstColonIndex),
        checkInType.substring(firstColonIndex + 1),
      ];

      return `Time – ${parts[1]}`;
    }

    return checkInType;
  }, []);


  const notes = useMemo(() => (
      <div>
        <div className={"font-semibold"}>Type</div>
        <div>{parseType(data.type)}</div>
        <div className={"font-semibold  mt-2"}>Notes</div>
        <div>{data.notes}</div>
      </div>
  ), [data.type, data.notes]);


  const notesAndActions = useCallback(() => {
    const checkInText = "Check In";
    const notesEditText = "View Notes";

    if (checkInPassed) {
      return <div className={"p-4 col-span-1 flex flex-col gap-4"}>
        {notes}
        <RedButton text={notesEditText} onClick={() => openStatusInfoPopUp(data.id)}/>
        <RedButton text={checkInText} onClick={() => openStatusChangePopUp(data.id)}/>
      </div>
    }

    if (checkInSoon) {
      return <div className={"p-4 col-span-1 flex flex-col gap-4"}>
        {notes}
        <OrangeButton text={notesEditText} onClick={() => openStatusInfoPopUp(data.id)}/>
        <OrangeButton text={checkInText} onClick={() => openStatusChangePopUp(data.id)}/>
      </div>
    }

    if (checkInUpcoming) {
      return <div className={"p-4 col-span-1 flex flex-col gap-4"}>
        {notes}
        <GreenButton text={notesEditText} onClick={() => openStatusInfoPopUp(data.id)}/>
        <GreenButton text={checkInText} onClick={() => openStatusChangePopUp(data.id)}/>
      </div>
    }

    return <div className={"p-4 col-span-1 flex flex-col gap-4"}>
      {notes}
      <BlueButton text={notesEditText} onClick={() => openStatusInfoPopUp(data.id)}/>
      <BlueButton text={checkInText} onClick={() => openStatusChangePopUp(data.id)}/>
    </div>
  }, [checkInPassed, checkInSoon, checkInUpcoming, data.id, notes, openStatusInfoPopUp, openStatusChangePopUp]);

  return (
      <div
          className={`grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 rounded-2xl ${bgClass} ${textClass}`}>
        <div className={"p-4"}>
          <div className={"font-semibold"}>Operator Time</div>
          <div className={"text"}>
            {moment(data.checkInAt).format("DD/MM/YYYY")} {dateToHoursAndMinutes(data.checkInAt)}
          </div>
          <div className={"text-sm"}>
            {moment.tz.guess(true)}
          </div>

          <div className={"mt-2 font-semibold"}>Traveller Time</div>
          <div className={"text"}>
            {formatDateToTimeZone(data.checkInAt, data.location.timezone)} {formatTimeToTimeZone(data.checkInAt, data.location.timezone)}
          </div>
          <div className={"text-sm"}>{data.location.timezone}</div>

          <div className={"mt-2 font-semibold"}>Grace Period</div>
          <div className={""}>
            {gracePeriodInMinutes(data)}
          </div>
        </div>

        <div className={"p-4 col-span-1"}>
          <div className={"font-semibold"}>Client</div>
          <div className={""}>{data.clientName}</div>

          {data.departmentName && <>
              <div className={"mt-2 font-semibold"}>Department</div>
              <div className={""}>{data.departmentName}</div>
          </>}

          {data.userName && <>
              <div className={"mt-2 font-semibold"}>User</div>
              <div className={"overflow-ellipsis truncate"}>{data.userName}</div>
          </>}

        </div>

        <div className={"p-4 col-span-1"}>
          <div className={"font-semibold"}>Location</div>
          {data.location.addressLines.filter(l => l).length != 0 &&
              <div className={""}>
                {data.location.addressLines.filter(l => l).join(", ")}
              </div>
          }

          {data.location.town &&
              <div className={""}>
                {data.location.town}
              </div>
          }

          {data.location.county &&
              <div className={""}>
                {data.location.county}
              </div>
          }

          {data.location.region &&
              <div className={""}>
                {data.location.region}
              </div>
          }
          {data.location.countryName &&
              <div className={""}>
                {data.location.countryName}
              </div>
          }

          {data.location.postalCode &&
              <div className={""}>
                {data.location.postalCode}
              </div>
          }


        </div>

        <div className={"p-4 col-span-1"}>
          <div className={"font-semibold"}>Email</div>
          <a className={"overflow-ellipsis truncate block"}
             href={`mailto:${data.userEmail}`}>{data.userEmail}</a>

          {phoneNumbers.length != 0 && <>
              <div className={"mt-2 font-semibold"}>Phone Numbers</div>
            {phoneNumbers.map((p, i) => <a key={`${p}_${i}`} className={" truncate overflow-ellipsis block"}
                                      href={`tel:${p}`}>{p}</a>)}
          </>}
        </div>

        <div className={"p-4 col-span-1"}>
          <div className={"font-semibold"}>Last Modified By</div>
          {!data.userEditable && <div className={""}>Automatically Generated</div>}
          {data.userEditable && <>
              <div className={"truncate overflow-ellipsis"}>{data.lastModifiedByUserName}</div>
              <div className={"truncate overflow-ellipsis"}>{data.lastModifiedByUserEmail}</div>
          </>}

          <div className={"font-semibold mt-2"}>Last Modified At</div>
          <div>{moment(data.lastModifiedAt).format("DD/MM/YYYY HH:mm")}</div>
          <div className={"text-sm"}>{moment.tz.guess(true)}</div>


        </div>

        {notesAndActions()}
      </div>
  )
}

export default memo(CheckInEntry, (prevProps, nextProps) => {
  return (
      prevProps.data.notes === nextProps.data.notes &&
      prevProps.checkInSoon === nextProps.checkInSoon &&
      prevProps.checkInPassed === nextProps.checkInPassed &&
      prevProps.checkInUpcoming === nextProps.checkInUpcoming
  );
});