import moment from "moment";
import { useMemo } from "react";
import convertDateToUTC from "../../../../../util/convertDateToUTC";
import useIncidents from "../../../../../services/useIncidents";

export default function useExtremeIncidentsInTimerange() {
  const sevenDaysAgoDateFromToday = moment().subtract(7, "days").format();
  const sevenDaysAfterFromToday = moment().add(7, "days").format();
  const incidentParams = useMemo(() => {
    const startTime = convertDateToUTC(sevenDaysAgoDateFromToday);
    const endTime = convertDateToUTC(sevenDaysAfterFromToday);
    return {
      severity: "extreme",
      startTime,
      endTime,
      rpp: 200,
    };
  }, [sevenDaysAgoDateFromToday, sevenDaysAfterFromToday]);

  return useIncidents("extreme-incidents-timerange", incidentParams);
}
