import React from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import FirstAidIcon from "../../../../../../assets/icons/first-aid-icon.svg";
import AlertIcon from "../../../../../../assets/icons/alert-icon.svg";
import EarthquakeIcon from "../../../../../../assets/icons/earthquake.svg";
import FlightIcon from "../../../../../../assets/icons/flight.svg";
import MainLink from "../../components/MainLink";
function AdviceGuidesMain() {
  const guide_name = useLocation().pathname.split("/");

  const Nav = () => (
    <nav className="w-full flex gap-2 px-8 mt-8">
      <MainLink
        title="Travel"
        icon={FlightIcon}
        to={"travel"}
        color={"#68AF6D"}
        active={guide_name.includes("travel") ? true : false}
      />
      <MainLink
        title="Natural Hazards"
        icon={EarthquakeIcon}
        to={"natural-hazards"}
        color={"#E6920A"}
        active={guide_name.includes("natural-hazards") ? true : false}
      />
      <MainLink
        key={"medical"}
        title="Medical"
        icon={FirstAidIcon}
        to={"medical"}
        color={"#C64805"}
        active={guide_name.includes("medical") ? true : false}
      />
      <MainLink
        key={"emergency"}
        title="Emergency"
        icon={AlertIcon}
        to={"emergency"}
        color={"#A15FB2"}
        active={guide_name.includes("emergency") ? true : false}
      />
    </nav>
  );

  return (
    <div className="flex-1 h-full bg-white">
      <Nav />
      <Outlet />
    </div>
  );
}

export default AdviceGuidesMain;
