import { useEffect, useRef, useState } from "react";
import { FacilityDataTypes } from "../../../types/facilitiy-data-types";
import { renderFacilitiyString, renderInfoWindow } from "../utils";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const useFacilitiesMarker = (
  map: google.maps.Map | undefined,
  facilities: FacilityDataTypes[],
  siteTypes: string[],
  clientID?: string
) => {
  const [isFacilities, setIsFacilities] = useState<boolean>();
  const facilitiesMarkerRef = useRef<google.maps.Marker[]>([]);
  const markerClustererRef = useRef<MarkerClusterer | null>(null);

  useEffect(() => {
    setIsFacilities(siteTypes.includes("facilities"));
  }, [siteTypes]);

  useEffect(() => {
    if (!map) return;
    const zoom = map.getZoom();
    if (zoom) {
      map.setZoom(zoom);
    }
    facilitiesMarkerRef.current.forEach((facility) => facility.setMap(null));
    if (isFacilities) {
      facilitiesMarkerRef.current = facilities
        .filter(
          (facility) =>
            !clientID || clientID === "all" || facility.clientID === clientID
        )
        .map((facility) => {
          const { coordinates } = facility.position;
          const [lng, lat] = coordinates;

          const marker = new google.maps.Marker({
            position: { lat, lng },
            clickable: true,
            label: {
              text: "apartment",
              fontFamily: "Material Icons",
              color: "#ffffff",
              fontSize: "18px",
            },
            optimized: true,
          });

          const facilityStringDiv = renderFacilitiyString(facility);
          const infowindow = renderInfoWindow(facilityStringDiv, facility.name);

          marker.addListener("click", () => {
            infowindow.open({ map, anchor: marker });
          });

          if (!isFacilities) {
            marker.setMap(null);
          }
          marker.setMap(map);
          return marker;
        });
        markerClustererRef.current = new MarkerClusterer({ map, markers: facilitiesMarkerRef.current });
    }
  }, [facilities, map, clientID, isFacilities]);

  return;
};

export default useFacilitiesMarker;
