import React, { useContext } from "react";
import { FacilityDataTypes } from "../../../../../../types/facilitiy-data-types";
import { FormDataContext } from "../../context/form";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
function StationedFacility({
  facilities,
}: {
  facilities: FacilityDataTypes[];
}) {
  const userProfileData = useContext(UserProfileContext);
  const userData: UserDataTypes = userProfileData?.data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { stationedFacilityRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="stationed_facility" className="opacity-80">
        Stationed Facility
      </label>
      <select
        className="h-10 w-full rounded-md shadow-md px-4 flex items-center justify-start"
        ref={stationedFacilityRef}
        defaultValue={
          userData.companyDetails?.stationedFacility
            ? userData.companyDetails?.stationedFacility
            : "select-facility"
        }
      >
        <option value={"select-facility"} disabled>
          Select Facility
        </option>
        {facilities?.map((facility) => (
          <option key={facility.id} value={facility.id} defaultChecked={false}>
            {facility.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default StationedFacility;
