import React, {useEffect, useState} from "react"
import SearchableSelect, {SearchableSelectOption} from "."
import {UserDataTypes} from "../../../types/user-data-types"
import api from "../../../api/axiosConfig"
import {PaginatedResponse} from "../../../types/PaginatedResponse"
import PeopleIcon from "../../../assets/icons/icon-person.svg";

type UserSelectParams = {
  clientID?: string | null;
  departmentID?: string | null;
  multiple?: boolean;
  onSelectionChange?: (users: UserDataTypes[]) => void
  users: UserDataTypes[],
  initialUserIDs?: string[],
  disabled?: boolean
}

function UserSelect({
                      users,
                      clientID,
                      departmentID,
                      multiple,
                      onSelectionChange,
                      initialUserIDs,
                      disabled,
                    }: UserSelectParams) {

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SearchableSelectOption<UserDataTypes>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const fetchUsers = async () => {
    setLoading(true)
    try {

      if (initialUserIDs && initialUserIDs.length > 0) {
        const initialUsers = await api.get<{ items: UserDataTypes[] }>(`/users?ids=${initialUserIDs.join(",")}`);
        if (onSelectionChange) {
          onSelectionChange(initialUsers.data.items)
        }
      }

      const res = await api.get<PaginatedResponse<UserDataTypes>>("/users", {
        params: {
          clientID,
          departmentID,
          search: searchStr,
          rpp: 200
        }
      })
      setOptions(res.data.items.map((u) => ({
        label: `${u.profile.firstName} ${u.profile.lastName}`,
        value: u,
        key: u.id
      })))

    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [clientID, departmentID, searchStr])

  useEffect(() => {
    onChange([])
  }, [clientID, departmentID])

  const onChange = (selected: SearchableSelectOption<UserDataTypes>[]) => {
    if (onSelectionChange) {
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value)
  }

  return (<SearchableSelect
      icon={PeopleIcon}
      label={multiple ? "Users" : "User"}
      selected={users.map(u => ({
        label: `${u.profile.firstName} ${u.profile.lastName}`,
        value: u,
        key: u.id
      }))}
      search={true}
      multiple={multiple ? true : false}
      options={options}
      localSearch={false}
      onChange={onChange}
      onSearch={onSearch}
      loading={loading}
      disabled={disabled}
  />)
}

export default UserSelect