import React, { useEffect, useState } from "react";
import EditIcon from "../../../../../../assets/icons/icon-edit.svg";
import BinIcon from "../../../../../../assets/icons/icon-bin.svg";
import SpyIcon from "../../../../../../assets/icons/icon-spy.svg";
import WhiteBinIcon from "../../../../../../assets/icons/icon-bin-white.svg";
import Modal from "../../../../../../components/portal/Modal";
import { RedCircle } from "../../../../../../components/ui/Circles";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { Link } from "react-router-dom";
import api from "../../../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
import ClearIcon from "@mui/icons-material/Clear";
function Row({ user }: { user: UserDataTypes }) {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const handleDeleteUser = () => {
    api
      .delete(`users/${user.id}`)
      .then(() => {
        queryClient.invalidateQueries("client-users");
        queryClient.invalidateQueries("users");
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="border-b-1 border-light-grey py-4 px-5 flex">
      <div className="grid grid-cols-5 text-center items-center w-full">
        <Link to={`/user-profile/${user.id}`}>
          <p className="">{`${user.profile.firstName} ${user.profile.lastName}`}</p>
        </Link>
        <div className="">{user.email}</div>
        <div className="capitalize">{user.roleName}</div>
        <div>{user.departmentName}</div>

        <div className="flex items-center justify-center">
          <button
            className="h-10 w-10 flex items-center justify-center"
            onClick={() => setDeleteModal(true)}
          >
            <img
              src={BinIcon}
              alt=""
              className="w-1/2 h-1/2 pointer-events-none"
            />
          </button>
        </div>
      </div>

      {deleteModal && (
        <Modal open={deleteModal} setOpen={setDeleteModal}>
          <div className=" bg-light-red p-4 relative w-fit h-fit rounded-lg max-w-md text-center">
            <div className=" flex flex-col gap-4 justify-center items-center w-full h-full bg-white p-4 pt-10 pb-20 rounded-lg">
              <button
                className="h-8 w-8 rounded-full absolute right-8 top-8"
                onClick={() => setDeleteModal(false)}
              >
                <ClearIcon />
              </button>
              <h3 className="font-bold text-lg">Are you sure?</h3>
              <p>
                You are about to delete{" "}
                <span className="font-semibold">{`${user.profile.firstName} ${user.profile.lastName}`}</span>
                . Please press the button below to confirm.
              </p>

              <button
                className="absolute w-fit h-fit bottom-8 left-2/4 translate-x-2/4 pl-4"
                onClick={handleDeleteUser}
              >
                <RedCircle icon={WhiteBinIcon} />
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Row;
