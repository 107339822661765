import {useContext, useEffect, useRef, useState} from "react";
import {FilterMapContext} from "../../../context/filter-map";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {apiGET} from "../../../../../apiv2/request";
import {useQuery} from "react-query";
import {ItineraryItemType} from "../../../../../pages/WorldSearch";
import moment from "moment";
import {startCase} from "lodash";

type WorldSearchRecord = {
  id: string
  type: string
  subtype: string
  location: {
    countryISO: string
    countryName: string
    region: string
    county: string
    town: string
    postalCode: string
    addressLines: Array<string>
    timezone: string
    point: {
      type: string
      coordinates: Array<number>
    }
    formatted: string
  }
  startTime: string
  endTime: string
  startCountryRisk: number
  endCountryRisk: number
  currentCountryRisk: number
  userID: string
  tripID: string
  userName: string
  userEmail: string
  userPhoneNumbers: {
    label: string
    number: string
    countryISO: string
    type: string
    primary: boolean
  }[]
  departmentName: string
  departmentID: string
  clientName: string
  clientID: string
  metadata: Record<string, string>
  trip: {
    id: string
    userID: string
    startTime: string
    endTime: string
    settings: {
      userEditable: boolean
      allowThirdPartyUpdates: boolean
    }
    source: {
      provider: string
      type: string
      id: string
      format: string
      rawData: string
    }
    checkInSettings: {
      types: Array<string>
      applyTo: Array<any>
      times: Array<string>
      days: Array<string>
      gracePeriod: number
      preventDuring: Array<any>
    }
    itinerary: ItineraryItemType[]
    status: {
      valid: boolean
      checkInsGenerated: boolean
      errors: string[]
    }
    deleted: boolean
    lastModifiedAt: string
  }
}


function renderContentString(r: WorldSearchRecord) {

  const start = moment(r.startTime).format("DD/MM/YYYY HH:mm");
  const end = moment(r.endTime).format("DD/MM/YYYY HH:mm");

  let datetime = `
    <div class="grid-header">Date/Time</div>
    <div class="grid-text">${start}</div>
  `

  if (start != end) {
    datetime = `
      <div class="grid-header">Start</div>
      <div class="grid-text">${start}</div>
      <div class="grid-header">End</div>
      <div class="grid-text">${end}</div>
    `
  }

  if (r.type == "trip") {

    const tripStart = moment(r.startTime).format("DD/MM/YYYY")
    const tripEnd = moment(r.endTime).format("DD/MM/YYYY")

    const phoneNumbers = r.userPhoneNumbers || []

    return `
    <div class="map-incident-dialog">
        <div class="grid-container">
            <div class="grid-header-full-width">User Details</div>
        
            <div class="grid-header">User</div>
            <div class="grid-text">${r.userName}</div>
            <div class="grid-header">Email</div>
            <div class="grid-text">${r.userEmail}</div>
            <div class="grid-header">Phone Number/s</div>
            <div class="grid-text">${phoneNumbers.filter(n => n.number).map(p => `<a href="tel:${p.number}">${p.number}</a>`).join("<br/>")}</div>
            
            <div class="grid-spacer"></div>
            <div class="grid-header-full-width">Source Details</div>
            <div class="grid-header">Type</div>
            <div class="grid-text">${startCase(r.type)}</div>
            <div class="grid-header">Subtype</div>
            <div class="grid-text">${startCase(r.subtype.split(":")[0])} &ndash; ${startCase(r.subtype.split(":")[1])}</div>
            
            ${datetime}
            
            <div class="grid-header">Country</div>
            <div class="grid-text">${r.location.countryName}</div>
            
            ${r.metadata && Object.keys(r.metadata).map((k) => `
              <div class="grid-header">${startCase(k)}</div>
              <div class="grid-text">${r.metadata[k] || "&ndash;"}</div>
            `).join(" ") || ""}
            
            
            <div class="grid-spacer"></div>
            <div class="grid-header-full-width">Trip Details</div>
            
            <div class="grid-header">Dates</div>
            <div class="grid-text">${tripStart} &ndash; ${tripEnd}</div>
   
            <div class="grid-header-full-width">
                <a class="button" href="trips?id=${r.tripID}" target="_blank"/>Show Trip</a>
            </div>
             <div class="grid-header-full-width">
                <a class="button" href="world-search?tripID=${r.tripID}" target="_blank"/>Show World Search</a>
            </div>
            <div/>
            
        
        </div>
    </div>
  `
  }
  return ""


}

const getType = (type: string) => {
  switch (type) {
    case "travel:flight":
      return "flight"
    case "travel:taxi":
      return "local_taxi"
    case "travel:ferry":
      return "directions_boat"
    case "travel:train":
      return "train"
    case "travel:rental":
      return "car_rental"
    case "travel:private_car":
      return "directions_car"
    case "travel:bus":
      return "directions_bus"
    case "travel:other":
      return "question_mark"
    case "travel:undefined":
      return "question_mark"

    case "accommodation:hotel":
      return "hotel"
    case "accommodation:private_property":
      return "home_work"
    case "accommodation:home":
      return "home"
    case "accommodation:other":
      return "question_mark"
    case "accommodation:undefined":
      return "question_mark"
  }

  return "question_mark"
}


const getIcon = (type: string) => {
  switch (type) {
    case "travel:flight":
      return "flight"
    case "travel:taxi":
      return "local_taxi"
    case "travel:ferry":
      return "directions_boat"
    case "travel:train":
      return "train"
    case "travel:rental":
      return "car_rental"
    case "travel:private_car":
      return "directions_car"
    case "travel:bus":
      return "directions_bus"
    case "travel:other":
      return "question_mark"
    case "travel:undefined":
      return "question_mark"

    case "accommodation:hotel":
      return "hotel"
    case "accommodation:private_property":
      return "home_work"
    case "accommodation:home":
      return "home"
    case "accommodation:other":
      return "question_mark"
    case "accommodation:undefined":
      return "question_mark"
  }

  return "question_mark"
}

// export const travelSubtypesTravel = [
//   {value: "flight", label: "Flight"},
//   {value: "taxi", label: "Taxi"},
//   {value: "ferry", label: "Ferry"},
//   {value: "train", label: "Train"},
//   {value: "rental", label: "Rental"},
//   {value: "private_car", label: "Private Car"},
//   {value: "bus", label: "Bus"},
//   {value: "other", label: "Other"},
//   {value: "undefined", label: "Undefined"},
// ]
//
// export const travelSubtypesAccommodation = [
//   {value: "hotel", label: "Hotel"},
//   {value: "private_property", label: "Private Property"},
//   {value: "home", label: "Home"},
//   {value: "other", label: "Other"},
//   {value: "undefined", label: "Undefined"},
// ]


export default function useWorldSearchMarkers(
    map: google.maps.Map | undefined
) {
  const [mergedMarkers, setMergedMarkers] = useState<google.maps.Marker[]>([]);
  const {filters} = useContext(FilterMapContext);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const markerClusterer = useRef<MarkerClusterer | null>(null);
  const sources: string[] = filters.userTypes.map((type, i) =>
      i < filters.userTypes.length - 1 ? `${type},` : type
  );
  const sourcesString = sources.join("");
  const {clientID, userTypes} = filters;

  const [results, setResults] = useState<WorldSearchRecord[]>([])

  const fetchWorldSearchRecords = async () => {

    const wsFilters = filters.worldSearchTypes?.filter(f => ["travel", "accommodation"].includes(f)) || []

    markers.forEach((m) => {
      m.setMap(null)
    })

    console.log("ws", wsFilters)

    if (wsFilters.length == 0) {
      return
    }

    const query = new URLSearchParams()
    if (filters.clientID && filters.clientID != "all") {
      query.set("clientID", filters.clientID)
    }

    if (wsFilters.length != 0) {

      const subtypes: string[] = []
      if (wsFilters.includes("accommodation")) {

        subtypes.push(
            "accommodation:hotel",
            "accommodation:private_property",
            "accommodation:home",
            "accommodation:other",
            "accommodation:undefined",
        )
      }

      if (wsFilters.includes("travel")) {
        subtypes.push(
            "travel:flight",
            "travel:taxi",
            "travel:ferry",
            "travel:train",
            "travel:rental",
            "travel:private_car",
            "travel:bus",
            "travel:other",
            "travel:undefined",
        )
      }


      query.set("subtypes", subtypes.join(","))
    }

    console.log("WSFILTERS", wsFilters)

    const results = await apiGET<WorldSearchRecord[]>(`${process.env.REACT_APP_API_V2_URL}/map/world-search?${query.toString()}`)


    markers.forEach((m) => {
      console.log(m)
      m.setMap(null)
    })

    const newMarkers: google.maps.Marker[] = []

    results.forEach((r, i) => {
      const marker = new google.maps.Marker({
        position: {lat: r.location.point.coordinates[1], lng: r.location.point.coordinates[0]},
        clickable: true,
        map,
        label: {
          text: getIcon(r.subtype),
          fontFamily: "Material Icons",
          color: "#ffffff",
          fontSize: "18px",
        },
        zIndex: i,
        title: "",
      });

      const infowindow = new google.maps.InfoWindow({
        content: renderContentString(r),
        ariaLabel: r.id,
      });
      marker.addListener("click", () => {
        infowindow.open({map, anchor: marker});
      });

      newMarkers.push(marker)

    })

    setMarkers(newMarkers)


  }

  useEffect(() => {
    console.log({map})
    fetchWorldSearchRecords()
  }, [map]);


  useQuery(
      ["map/world-search", filters],
      fetchWorldSearchRecords
  );


}
