import React, {
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useUsers from "../../../../services/useUsers";
import { BlueButton } from "../../../../components/ui/Buttons";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { Select } from "../../../../components/form-v2";
import SuitcaseIcon from "../../../../assets/icons/suitcase.svg";
import { UserDataTypes } from "../../../../types/user-data-types";
import ClientSelect from "../../../../components/form-v2/client-select";
import { FilterMapContext } from "../../context/filter-map";
import { useSearchParams } from "react-router-dom";
import { UserLocationHistory } from "../../components/Map/services/useLocationHistory";

type Option = { value: string; label: string };

function UserTrackingForm({
  setUserFilter,
  userFilter,
  userLocations,
}: {
  setUserFilter: React.Dispatch<SetStateAction<boolean>>;
  userFilter: boolean;
  userLocations: void | UserLocationHistory[] | undefined;
}) {
  const { filters, setFilters } = useContext(FilterMapContext);
  const [usersLoading, setUsersLoading] = useState<boolean>(true);
  const [usersOptions, setUsersOptions] = useState<Option[]>([]);
  const [defaultUser, setDefaultUser] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [clientID, setClientID] = useState<string | null>();
  const { data: users, hasNextPage: usersHasNextPage } = useUsers({
    clientID: clientID,
  }).usersQuery;

  const selectedUserID = filters.trackingUserID;

  useEffect(() => {
    if (!users) return;
    const options: Option[] = users
      .filter((user: UserDataTypes) => user)
      .map((user: UserDataTypes) => ({
        label: `${user.profile.firstName} ${user.profile.lastName}`,
        value: user.id,
      }));
    setUsersOptions(options);
  }, [users]);

  const userFilterFormRef = useRef<HTMLFormElement>(null);
  const handleUserTracking = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(userFilterFormRef.current!);
    const userID = formData.get("users") as string;
    setFilters((prev) => ({ ...prev, trackingUserID: userID }));
  };

  useOnClickOutside(userFilterFormRef, () => setUserFilter(false));

  useEffect(() => {
    if (usersHasNextPage) {
      setUsersLoading(true);
    } else {
      setUsersLoading(false);
    }
  }, [usersHasNextPage]);

  useEffect(() => {
    const selectedUser = usersOptions.find(
      (user) => user.value === selectedUserID
    )!;
    setDefaultUser(selectedUser);
  }, [selectedUserID, usersOptions]);

  return (
    <form
      className="bg-white rounded-md p-6 flex flex-col gap-4 absolute left-[104px] top-14 max-w-md w-full"
      ref={userFilterFormRef}
      onSubmit={handleUserTracking}
    >
      <span className="text-l font-semibold">User Tracking History</span>

      {selectedUserID && userLocations && userLocations.length > 1 ? (
        <BlueButton type="submit" text="Stop Tracking" />
      ) : (
        <>
          <ClientSelect onSelect={(value) => setClientID(value)} />
          {usersLoading ? null : (
            <Select
              name="users"
              options={usersOptions}
              title="Users"
              icon={SuitcaseIcon}
              defaultSelected={defaultUser}
              loading={usersLoading}
            />
          )}
          {userLocations && userLocations.length < 1 && (
            <p>No location history in the last 24 hours.</p>
          )}
          <BlueButton type="submit" text="Track" />
        </>
      )}
    </form>
  );
}

export default UserTrackingForm;
