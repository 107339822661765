import React, { useContext, useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import { FilterMapContext } from "../../../context/filter-map";

type UserTypeListItemTypes = {
  item: { slug: string; value: string; label: string };
  selected: boolean;
  onChange: (key: string, value:boolean) => void
};

function UserTypeListItem({ item, selected, onChange }: UserTypeListItemTypes) {
  const { filters } = useContext(FilterMapContext);
  



  return (
    <div className={` ${selected ? null : "opacity-50"}`}>
      <Checkbox
        name="user_type"
        label={item.label}
        checked={selected}
        value={item.value}
        onClick={() => {
          onChange(item.value, !selected)
        }}
        disabled={filters.trackingUserID ? true : false}
      />
    </div>
  );
}

export default UserTypeListItem;
