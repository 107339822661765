import React, { ReactElement } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import Map from "./Map";
import LoadingSpinner from "../../../../components/ui/LoadingSpinner";

function OperationalMap() {
  const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
  const Error = () => <h1>Failed to load map.</h1>;
  const Loader = () => (
    <div className="h-full w-full flex justify-center items-center">
      <LoadingSpinner />
    </div>
  );
  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Error />;
    return <Loader />;
  };

  return (
    <div className="w-full relative h-full">
      <Wrapper
        apiKey={googleMapsApiKey}
        render={render}
        libraries={["drawing", "places"]}
      >
        <Map />
      </Wrapper>
    </div>
  );
}

export default OperationalMap;
