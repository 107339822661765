import React, { useState } from "react";
import AddIcon from "../../../../../assets/icons/add-icon.svg";
import Checkbox from "../../../../../components/form/Checkbox";
import Label from "../../../../../components/form/Label";
import Search from "../../../../../components/form/Search";
import { GreyButton } from "../../../../../components/ui/Buttons";
function StepThree() {
  const [isAppPlus, setIsAppPlus] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isSMS, setIsSMS] = useState<boolean>(false);
  const [isChat, setIsChat] = useState<boolean>(false);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-center">
        <Label text="Step 3:" assignText="Select communication method" />
      </div>

      <div className="">
        <Checkbox
          label="App"
          name="communication-method"
          value="app-plus"
          checked={isAppPlus}
          onClick={() => setIsAppPlus((prev) => !prev)}
        />
        <Checkbox
          label="Email"
          name="communication-method"
          value="email"
          checked={isEmail}
          onClick={() => setIsEmail((prev) => !prev)}
        />
        <Checkbox
          label="SMS"
          name="communication-method"
          value="sms"
          checked={isSMS}
          onClick={() => setIsSMS((prev) => !prev)}
        />
        <Checkbox
          label="Chat"
          name="communication-method"
          value="chat"
          checked={isChat}
          onClick={() => setIsChat((prev) => !prev)}
        />
      </div>
    </div>
  );
}

export default StepThree;
