type UserTypeListsTypes = {
  slug: string;
  value: string;
  label: string;
}[];

export const UserTypeLists: UserTypeListsTypes = [
  {
    slug: "app",
    value: "app",
    label: "App Users",
  },
  {
    slug: "travellers",
    value: "trip",
    label: "Travellers",
  },
  {
    slug: "facility",
    value: "facility",
    label: "Static Employees",
  },
  {
    slug: "satellite",
    value: "satellite",
    label: "Satellite",
  },
];

export const WorldSearchTypes: UserTypeListsTypes = [
  {
    slug: "travel",
    value: "travel",
    label: "Travel",
  },
  {
    slug: "accommodation",
    value: "accommodation",
    label: "Accommodation",
  },
];
