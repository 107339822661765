import React from "react";

export default function TableHeader() {
  const headerTitles = ["User Name", "Email", "Role", "Department", "Actions"];

  return (
    <div className="flex py-3 px-5 rounded-2xl bg-desaturated-grey mt-5 items-center">
      <div className="grid grid-cols-5 justify-items-center w-full">
        {headerTitles.map((title, index) => (
          <div
            className="text-center font-bold w-full border-r-1 border-r-light-grey last:border-none"
            key={index}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
}
