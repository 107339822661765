import React from "react";
import Label from "../../../../../components/form/Label";

import UserTypeListItem from "./UserTypeListItem";
import {WorldSearchTypes} from "../../../types/user-type-list-types";

function WorldSearchType({selected, onChange}: { selected: string[], onChange: (values: string[]) => void }) {


  const onItemChange = (key: string, value: boolean) => {

    console.log(key, value)
    if (value) {
      onChange([...(selected || []), key])
      return
    }

    const index = selected.indexOf(key);
    onChange([...selected.slice(0, index), ...selected.slice(index + 1)])

  }

  return (
      <div className="flex flex-col gap-2">
        <Label text="Trips" htmlFor={"world-search-type"}/>

        <div className="flex flex-col gap-2">
          {WorldSearchTypes.map((item) => (
              <UserTypeListItem key={item.value} item={item} selected={(selected || []).includes(item.value)}
                                onChange={onItemChange}/>
          ))}
        </div>
      </div>
  );
}

export default WorldSearchType;
