import {useContext, useEffect, useRef, useState} from "react";
import {FilterMapContext} from "../../../context/filter-map";
import api from "../../../../../api/axiosConfig";
import {useQuery} from "react-query";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {downloadCSVFile} from "../../../../../util/downloadCSVFile";
import moment from "moment";

export type UsersLocation = {
  id: number[];
  users: {
    _id: string;
    clientID: string;
    clientName: string;
    departmentID: string;
    departmentName: string;
    email: string;
    coordinates: number[];
    receivedAt: string;
    source: string;
    firstName: string;
    lastName: string;
    phoneNumbers: {
      label: string;
      number: string;
      countryISO: string;
      carrier: string;
      type: string;
      primary: boolean;
      country: string;
    }[];
  }[];
  size: number;
};

function renderCluster(c: UsersLocation) {
  const users = c.users.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));

  const key = c.id.join("-").replace(/\./g, "_")

  return `
    <div class="map-incident-dialog">
    <label for="select-${key}">Select User</label>
    <select id="select-${key}">
        ${users.map((u, i) => `<option value="${i}">${u.firstName} ${u.lastName}</option>`).join("\n")}
    </select>

    <div id="content-${key}" class="container">
        ${renderClusterUser(c, 0)}
    </div>
    <button id="button-${key}" type="button" class="button" style="font-weight: bold">Download CSV</button>
         
    
    </div>
  
  `


}

function renderClusterUser(userCluster: UsersLocation, index: number) {
  const user = userCluster.users[index];
  const phoneNumber =
      user.phoneNumbers?.filter((num) => num.primary)[0]?.number ||
      "No phone number";

  const source = user.source === "facility" ? "Stationed Facility" : user.source

  const contentString = `
  
    <div class="grid-container">
        <div class="grid-header">Name</div>
        <div class="grid-text">${user.firstName} ${user.lastName}</div>
        <div class="grid-header">Phone Number</div>
        <div class="grid-text">${phoneNumber}</div>
        <div class="grid-header">Email</div>
        <div class="grid-text">${user.email}</div>
        <div class="grid-header">Client</div>
        <div class="grid-text">${user.clientName}</div>
        <div class="grid-header">Source</div>
        <div class="grid-text">${source}</div>
        <div class="grid-header-full-width">
            <a
                class="button"
                href="world-search?userID=${user._id}&startDate=${moment().subtract(1, "month").format("YYYY-MM-DD")}&endDate=${moment().add(1, "month").format("YYYY-MM-DD")}" target="_blank"
            />
                Show World Search
            </a>
        </div>
    </div>
 `;

  return contentString;
}


function renderContentString(userCluster: UsersLocation) {
  const user = userCluster.users[0];
  const phoneNumber =
      user.phoneNumbers?.filter((num) => num.primary)[0]?.number ||
      "No phone number";

  const source = user.source === "facility" ? "Stationed Facility" : user.source

  const contentString = `
    <div class="map-incident-dialog">
     <div class="grid-container">
        <div class="grid-header">Name</div>
        <div class="grid-text">${user.firstName} ${user.lastName}</div>
        <div class="grid-header">Phone Number</div>
        <div class="grid-text">${phoneNumber}</div>
        <div class="grid-header">Email</div>
        <div class="grid-text">${user.email}</div>
        <div class="grid-header">Client</div>
        <div class="grid-text">${user.clientName}</div>
        <div class="grid-header">Source</div>
        <div class="grid-text">${source}</div>
        <div class="grid-header-full-width">
            <a
                class="button"
                href="world-search?userID=${user._id}&startDate=${moment().subtract(1, "month").format("YYYY-MM-DD")}&endDate=${moment().add(1, "month").format("YYYY-MM-DD")}" target="_blank"
            />
                Show World Search
            </a>
        </div
  
    </div>
  </div>`;

  return contentString;
}

export default function useUserLocationMarkers(
    map: google.maps.Map | undefined
) {
  const [mergedMarkers, setMergedMarkers] = useState<google.maps.Marker[]>([]);
  const {filters} = useContext(FilterMapContext);
  const allMarkers = useRef<google.maps.Marker[]>([]);
  const markerClusterer = useRef<MarkerClusterer | null>(null);
  const sources: string[] = filters.userTypes.map((type, i) =>
      i < filters.userTypes.length - 1 ? `${type},` : type
  );
  const sourcesString = sources.join("");
  const {clientID, userTypes} = filters;

  const fetchUsersLocation = async () => {
    return await api
        .get("ops/user-locations-clustered", {
          params: {
            sources: sourcesString,
            clientID: clientID === "all" ? null : clientID,
          },
        })
        .then((res) => res.data as UsersLocation[])
        .catch((err) => console.error(err));
  };

  const usersLocationQuery = useQuery(
      ["users-location-v3", clientID, userTypes],
      fetchUsersLocation
  );

  const {data, isLoading} = usersLocationQuery;
  useEffect(() => {
    if (!map) return;
    if (!markerClusterer.current) {
      markerClusterer.current = new MarkerClusterer({map});
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const sameLocation =
        data
            ?.filter((cluster) => cluster.size !== 1)
            ?.map((userCluster) => {
              const lng = userCluster.id[0];
              const lat = userCluster.id[1];
              const userIDsInString = userCluster.users.map((user) => user._id);
              const contentString = renderCluster(userCluster);

              const infowindow = new google.maps.InfoWindow({
                content: contentString,
                ariaLabel: `${userCluster.id[0]}${userCluster.id[1]}`,
              });
              const marker = new google.maps.Marker({
                position: {lat, lng},
                clickable: true,
                label: {
                  text: "groups",
                  fontFamily: "Material Icons",
                  color: "#ffffff",
                  fontSize: "18px",
                },
              });

              marker.addListener("click", () => {
                infowindow.open({map, anchor: marker});

                google.maps.event.addListener(infowindow, "domready", () => {

                  const key = userCluster.id.join("-").replace(/\./g, "_")
                  const select = document.getElementById(`select-${key}`) as HTMLSelectElement
                  console.log(key, select)

                  if (!select) {
                    return
                  }

                  select.addEventListener("change", (e) => {

                    const contentID = `content-${key}`
                    const content = document.getElementById(contentID) as HTMLDivElement
                    const target = e.target as HTMLSelectElement;
                    content.innerHTML = renderClusterUser(userCluster, Number(target.value))
                  })

                  const button = document.getElementById(`button-${key}`) as HTMLButtonElement

                  button.addEventListener("click", (e) => {
                    downloadCSVFile(
                        `${process.env.REACT_APP_API_URL}users-locations-csv?userIDs=${userIDsInString.join(
                            ","
                        )}`,
                        `users-${key}.csv`
                    );
                  })

                })


                // downloadCSVFile(
                //   `${process.env.REACT_APP_API_URL}users-locations-csv?userIDs=${userIDsInString.join(
                //     ","
                //   )}`,
                //   "users.csv"
                // );
              });
              return marker;
            }) || [];
    const differentLocation =
        data
            ?.filter((cluster) => cluster.size === 1)
            .map((userCluster) => {
              const lng = userCluster.id[0];
              const lat = userCluster.id[1];
              const user = userCluster.users[0];
              const contentString = renderContentString(userCluster);
              const infowindow = new google.maps.InfoWindow({
                content: contentString,
                ariaLabel: user._id,
              });
              const marker = new google.maps.Marker({
                position: {lat, lng},
                clickable: true,
                label: {
                  text: "person",
                  fontFamily: "Material Icons",
                  color: "#ffffff",
                  fontSize: "18px",
                },
              });
              marker.addListener("click", () => {
                infowindow.open({map, anchor: marker});
              });
              return marker;
            }) || [];

    const allUserMarkers = [...sameLocation, ...differentLocation];
    setMergedMarkers(allUserMarkers);
  }, [data, map]);

  useEffect(() => {
    markerClusterer.current?.clearMarkers();
    markerClusterer.current?.addMarkers(mergedMarkers);
  }, [mergedMarkers]);

  useEffect(() => {
    if (!map || isLoading) return;
    allMarkers.current.forEach((marker) => marker.setMap(null));
    allMarkers.current = [];
    allMarkers.current = mergedMarkers;
    allMarkers.current.forEach((marker) => marker.setMap(map));
  }, [mergedMarkers, map, isLoading]);

  return usersLocationQuery;
}
