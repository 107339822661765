import React, { useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import Label from "../../../../../components/form/Label";
import Search from "../../../../../components/form/Search";

function StepOne() {
  const [sendToAll, setSendToAll] = useState<boolean>(false);
  const [sendToNonRespondees, setSendToNonRespondees] =
    useState<boolean>(false);
  const [sendToRespondees, setSendToRespondees] = useState<boolean>(false);
  return (
    <div>
      <div className="flex items-center justify-center">
        <Label
          text="Step 1:"
          assignText="Select people to receive notification"
        />
      </div>

      <div className="w-fit mx-auto flex flex-col gap-2 justify-center my-2">
        <Checkbox
          label="Send to All"
          name="send-to-all"
          value="send-to-all"
          checked={sendToAll}
          onClick={() => setSendToAll((prev) => !prev)}
        />
        <Checkbox
          label="Send to Non-respondees"
          name="send-to-non-respondees"
          value="send-to-non-respondees"
          checked={sendToNonRespondees}
          onClick={() => setSendToNonRespondees((prev) => !prev)}
        />
        <Checkbox
          label="Send to Respondees"
          name="send-to-respondees"
          value="send-to-respondees"
          checked={sendToRespondees}
          onClick={() => setSendToRespondees((prev) => !prev)}
        />
      </div>
    </div>
  );
}

export default StepOne;
